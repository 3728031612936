/* You can add global styles to this file, and also import other style files */

@import "mixins.scss";
@import "variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

//font-family
@font-face {
    font-family: $regular-font;
    src: url("./assets//fonts/TitilliumWeb-Regular.ttf");
}

@font-face {
    font-family: $light-font;
    src: url("./assets//fonts/TitilliumWeb-Light.ttf");
}

@font-face {
    font-family: $semibold-font;
    src: url("./assets//fonts/TitilliumWeb-SemiBold.ttf");
}

@font-face {
    font-family: $webbold-font;
    src: url("./assets//fonts/TitilliumWeb-Bold.ttf");
}

html {
    scrollbar-width: none;
}

body {
    font-family: $regular-font !important;
    background: $background_set;
    -ms-overflow-style: none;
    /* IE and EDGE */
    scrollbar-width: none;
    /* Firefox */
    overflow: hidden;
    margin: 0;
    zoom: 0.9;
    zoom: 90%;

    @media screen and (max-height:450px) {
        overflow-y: scroll !important;
    }
}

::-webkit-scrollbar {
    display: none;
}

:focus-visible {
    outline: none;
}

.form-check-input:focus {
    box-shadow: none;
}

//over-lay-body---
#overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
}

// user-guide-css
.guided-back-drop {
    opacity: 0.5;
    pointer-events: none;
}

.guided-visible-sec {
    pointer-events: none;
}

//login & register gobal
#user-guide-tour {
    background: $fade_out;
}

app-log-in,
app-not-found {

    .btn-outline-warning.disabled,
    .btn-outline-warning:disabled {
        color: $text-color;
        background-color: transparent;
        opacity: 1;
    }
}

.fw-bold {
    color: $text-color;
    font-family: $webbold-font;
}

.import {
    color: $red-color;
}

.user-card {
    width: 537px;
    background-color: $white;
    padding: 40px;
    box-shadow: 1px 3px 20px 0 rgba(0, 0, 0, 0.14);
    top: 50%;

    @include device-xls {
        top: 50%;
    }

    p {
        font-size: $font-16;
        font-family: $regular-font;
        line-height: 22px;
    }

    @media screen and (max-height:800px) {
        padding: 20px;
    }

    @media screen and (max-height:650px) {
        padding: 15px 20px 10px !important;
    }

    &.guide-tour {
        width: 538px;
        height: 623.5px;
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);

        .head-sec {
            color: $text-color;
            padding-top: 40px !important;
            letter-spacing: 0.32px;
        }

        p {
            font-size: $font-16;
            font-family: $regular-font;

            &.update-font {
                font-family: $webbold-font;
            }
        }

        .link {
            font-family: $regular-font;
        }
    }

    @include device-md {
        margin: 10px;

        @include device-sm {
            margin: 0;
        }
    }

    @include device-xls {
        width: 460px;
        padding: 30px;

        p {
            color: $dark-black1;
        }

        button {
            span {
                font-size: $font-20;
                font-family: $regular-font;
            }
        }

        .google,
        .microsoft {
            width: 40px;
        }

        .google {
            padding: 0 2px;
        }

        label {
            font-size: $font-15;
            margin: 0;
        }

        @include device-xs {
            .google-btn-align {
                left: -8px;
            }

            button {
                span {
                    font-size: $font-15;
                }
            }

            .user-card p {
                font-size: $font-14;
            }

            .logo {
                width: 272px;
            }
        }
    }

    @include device-md {
        width: 400px;
    }

    &.shipment-quick-srch {

        h3 {
            color: $text-color;
            font-size: $font-16;
            font-family: $webbold-font;
            letter-spacing: 0.32px;
            margin-bottom: 0;

            @include device-sm {
                font-size: $font-14;
            }
        }

        .red {
            font-family: $regular-font;
            color: $red-color;
            font-size: $font-16;
            line-height: normal;
            margin-top: 7px;

            @include device-xls {
                font-size: $font-14;
            }
        }

        label {
            margin: 10px 0;
            font-size: $font-15;
        }

        .ng-autocomplete {
            width: 100%;
            max-width: 100%;
            display: table;
            margin: 0 auto;
            position: relative;

            .input-container {
                input {
                    font-family: $regular-font;
                    border: 2px solid $border-color;
                    border-radius: 5px;
                    color: $dark-black1;
                    height: 38px;
                    font-size: $font-15;
                    box-sizing: border-box;
                    box-shadow: none;
                    outline: none;
                    background-color: $white;
                    width: 100%;
                    padding: 0 45px 0 15px;
                    line-height: 40px;

                    &:hover,
                    &:focus {
                        border: 2px solid $blue-one;
                    }

                    @include device-sm {
                        font-size: $font-15;
                    }
                }
            }
        }
    }
}

.btn-check:focus+.btn-outline-warning,
.btn-outline-warning:focus {
    box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled):active {
    background-color: $bgcolor;
}

.button-outline {
    border: solid 2px $yellow;
    width: 100%;
    font-size: $font-24;
    color: $text-color;

    &:hover,
    &:focus {
        background-color: $yellow-one;
        color: $text-color;
    }
}

.link {
    color: $text-color !important;
    text-decoration: none;
    cursor: pointer;
    font-family: $semibold-font;

    &:hover {
        text-decoration: none;
        color: $text-color;
    }

    &.tex {
        font-size: $font-16;

        @include device-sm {
            font-size: $font-14;
        }
    }
}

.admin-top-sm {
    padding-top: 80px;

    @include device-sm {
        padding-top: 90px !important;
        position: relative;
    }
}

.form-control:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: none;
}

.grey-color {
    color: $text-color !important;
}

.google-btn-align {
    position: relative;
    left: -13px;
}

.micro-btn-align {
    position: relative;
    right: -13px;
}

.register-now {
    color: $dark-black1;
    font-size: $font-16;
}

.google,
.microsoft {
    width: 8%;
}

.register input {
    border: solid 2px $border-color;
    color: $dark-black1;
    font-size: $font-16;

    &:hover,
    &:focus {
        background: $bgcolor;
        color: $dark-black1;
    }
}

.yellow-button {
    background-color: $yellow;
    border-color: transparent;
    color: $text-color;

    &:hover {
        background-color: $yellow-one !important;
        border-color: transparent;
        color: $text-color;
    }
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle,
.btn-check:focus+.btn-primary,
.btn-primary:focus {
    color: $text-color;
    background-color: $yellow-one;
    border-color: transparent;
    box-shadow: none;
}

@include device-xll {
    .tableFixHead tbody tr td.table-width-response {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 200px;
        display: inline-block;
        padding: 18px 14px;
    }

    .edocs {
        .tableFixHead tbody tr td.table-width-response {
            padding: 13px 0;

            @media screen and (max-width:430px) {
                width: 180px !important;
            }
        }
    }
}

.tableFixHead #tblMileStone tbody tr td.table-width-response {
    @include device-xll {
        padding: 13px 0.45rem !important;
    }
}

.tableFixHead #tblAdditionalReference tbody tr td.table-width-response {
    @include device-xll {
        padding: 13px 0 !important;
    }
}

app-workflow-filter,
app-datafield-filters,
app-datafield-management,
app-customs-custom-filters,
app-shipment-custom-filters,
app-booking-custom-filters,
app-action-field-filters,
app-report-filter,
app-template-filters,
.new-shipment-top-row-ctr {
    .dropdown-menu {
        padding: 0 !important;
        top: 6px !important;
    }

    /* Base for label styling */
    [type="checkbox"]:not(:checked),
    [type="checkbox"]:checked {
        position: absolute;
        left: 0;
        opacity: 0.01;
    }

    [type="checkbox"]:not(:checked)+label,
    [type="checkbox"]:checked+label {
        position: relative;
        padding-left: 1.5em;
        font-size: $font-16;
        line-height: 1;
        cursor: pointer;

        @include device-md {
            font-size: $font-12;
        }

        @include device-sm {
            font-size: $font-14;
            line-height: inherit;
            padding-left: 32px;
            padding-top: 2px;
        }
    }

    /* checkbox aspect */
    [type="checkbox"]:not(:checked)+label:before,
    [type="checkbox"]:checked+label:before {
        content: "";
        position: absolute;
        left: 0;
        top: 4px;
        width: 1em;
        height: 1em;
        border: 1px solid $text-color;
        background: $white;
        border-radius: 0.2em;

        -webkit-transition: all 0.275s;
        transition: all 0.275s;

        @include device-sm {
            width: 20px;
            height: 20px;
        }
    }

    [type="checkbox"]:not(:checked)+label:before {
        top: 4px;
    }

    /* checked mark aspect */
    [type="checkbox"]:not(:checked)+label:after,
    [type="checkbox"]:checked+label:after {
        background: url(/../../assets/img/cross-icon.svg) no-repeat;
        content: "";
        position: absolute;
        top: 8px;
        left: 4px;
        color: $text-color;
        line-height: 0;
        transition: all 0.2s;
        height: 8px;
        width: 10px;
        background-size: 8px 8px;

        @include device-md {
            top: 6px;
            left: 2px;
        }

        @include device-sm {
            top: 10px;
            font-size: 1.2em;
            height: 10px;
            width: 12px;
            background-size: 12px 9px;
            left: 4px;
        }
    }

    /* checked mark aspect changes */
    [type="checkbox"]:not(:checked)+label:after {
        opacity: 0;
        -webkit-transform: scale(0) rotate(45deg);
        transform: scale(0) rotate(45deg);
    }

    [type="checkbox"]:checked+label:after {
        opacity: 1;
        -webkit-transform: scale(1.2) rotate(0);
        transform: scale(1.2) rotate(0);
    }

    /* Disabled checkbox */
    [type="checkbox"]:disabled:not(:checked)+label:before,
    [type="checkbox"]:disabled:checked+label:before {
        box-shadow: none;
        border-color: #bbb;
        background-color: #e9e9e9;
    }

    [type="checkbox"]:disabled:checked+label:after {
        color: #777;
    }

    [type="checkbox"]:disabled+label {
        color: #aaa;
    }
}

app-booking-global-search {
    .quick-search-wrap h3 {
        padding-top: 20px;
    }

    .bookings-total,
    .remove-global-search {
        display: none;
    }

    .shipment-pickup-txt {
        font-size: $font-12 !important;
    }

    .booking-road-map {
        width: 52%;
        margin-left: 20px;

        @include device-md {
            width: 55%;
            margin-left: 0;

            @include device-sm {
                width: 96%;
                margin: 4px;
            }
        }
    }

    .road-maps .timeline li {
        .rap {
            visibility: hidden;
        }

        &:first-child .rap,
        &:last-child .rap {
            visibility: visible;

            h6 {
                font-size: $font-12 !important;
            }

            p {
                font-size: $font-12 !important;
            }
        }
    }

    @include device-md {
        .booking-btn {
            font-size: $font-12 !important;
        }

        .shiping-list-card {
            height: 110px;
        }

        .dashboard-state {
            width: 160px !important;
            flex: inherit;

            @include device-sm {
                text-align: right !important;
                width: 50% !important;
                flex: inherit;
            }
        }

        .bashboard-icon {
            width: 24% !important;
            flex: inherit;

            @include device-sm {
                width: 50% !important;
            }
        }

        @include device-sm {
            .road-maps .timeline li {
                &::before {
                    height: 5px !important;
                    width: 5px !important;
                    border-radius: 50% !important;
                    box-shadow: 0 0 0px #feffff inset, 0 0 0px 5px #e9ecef, 0 0 5px #000 !important;
                }

                &::after {
                    height: 1px !important;
                    top: 2px !important;
                }
            }

            .shiping-list-card .status-img .status-contant {
                padding-left: 0 !important;

                .shipment-no,
                .shipment-ref-no {
                    font-size: $font-12 !important;
                }
            }

            .status-img>div {
                margin: 0 !important;
            }

            .road-maps {
                width: 100% !important;

                .timeline {
                    top: -12px !important;

                    li .rap {
                        top: 14px !important;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1550px) {
    .page-title {
        font-size: 32px;
        padding: 10px 20px;
    }
}

@media screen and (max-width: 900px) {
    .button-outline {
        font-size: $font-16;
    }

    .user-card {
        p {
            font-size: $font-14;
        }

        label {
            font-size: $font-12;
            margin: 0;
        }
    }
}

@media screen and (max-width: 767px) {
    .user-card {
        width: 335px;
        padding: 20px;
    }

    .user-card button,
    .register-now {
        font-size: $font-15;
    }

    .admin-top-sm {
        padding-top: 130px;
    }

    .admin-mobile-menu .form-select {
        padding: 2px 30px 2px 5px;
        font-weight: 600;
        border: transparent;
        font-size: $font-12;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
    }

    .admin-mobile-menu .form-select option {
        font-weight: 600;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
        border: transparent;
        font-size: $font-12;
    }

    .admin-mobile-menu select {
        -webkit-appearance: none;
        border: transparent;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
        -moz-appearance: none;
        appearance: none;
    }

    .top-page-heading {
        font-size: $font-16;
        margin-top: 30px;
    }
}

//Global-styles
.heading-title {
    color: $text-color;
    font-size: 40px;
    font-family: $light-font;
}

.top-page-heading {
    font-size: $font-24;
    color: $dark-black1;
    font-family: $semibold-font;
    margin-bottom: 10px;

    @include device-md {
        font-size: $font-16;
        padding: 20px 6px 0 !important;

        @include device-sm {
            padding-top: 0 !important;
            margin-top: 10px;
        }
    }

    &.edoc-top-page-heading {
        margin-top: 100px;

        @include device-md {
            padding: 6px 0 6px 4px !important
        }
    }
}

.dropdown-item:focus,
.dropdown-item:hover,
.dropdown-item:active {
    background-color: transparent;
    color: $dark-black2;
}

.dropdown:focus-visible {
    outline: none;
}

.table tbody tr td.collapsed .cls-1 {
    stroke: $dark-black1 !important;
}

app-sucess-toast {
    position: fixed;
    right: 0;
    left: 0;
    top: 20px;
    z-index: 9999;
}

app-account-filter,
app-user-management {
    .dropdown-item {
        padding: 11px 16px;

        @include device-md {
            padding: 8px 12px;
        }
    }

    .dropdown-item.active,
    .dropdown-item:active {
        background-color: transparent;
    }
}

app-account-filter>div {
    @include device-md {
        padding: 0 3px;
    }
}

.custom-drop-menu {
    min-width: 100%;
    margin: 0;
    border: none;
    width: 100%;
    padding: 0;
    box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.14);

    &.remove-box {
        box-shadow: none;
    }
}

.custom-drop-menu {
    li label {
        margin-bottom: 0px;
    }

    li:hover {
        background: $bgcolor;
    }
}

#sortSection {
    text-align: right;
}

.selection-list {
    color: $dark-black1;
    font-size: $font-14;

    .header-set {
        font-family: $semibold-font;
        display: block;
        padding: 0 0 4px 0;

        @media screen and (min-device-width: 768px) and (max-device-width: 991px) {
            position: relative;
            top: -2px;
        }
    }
}

//Global-table-style
.table thead th,
.table thead th {
    border: none;
    font-weight: 500;
    font-size: $font-14;
}

.table td,
.table th {
    border: none;
}

.table>tbody {
    border: none !important;
}

.table tbody tr {
    box-shadow: 0 0 6px 0 rgb(0 0 0 / 12%);
    background-color: $white;

    &:hover {
        background-color: $white;
        --bs-table-accent-bg: none;
        box-shadow: 0 0 6px 0 rgb(0 0 0 / 12%);
    }
}

.tableFixHead.max-h {
    max-height: 335px;
    height: 100%;
}

.table.log-table-border {
    @include device-md {
        table-layout: fixed;
    }

    thead th {
        vertical-align: initial;

        &:first-child {
            padding-left: 0;
        }

        @include device-sm {
            white-space: unset;
        }
    }

    tbody tr {
        background-color: transparent;
        box-shadow: none;

        td {
            border-bottom: 1px solid $border-color;
            font-size: $font-16;
            vertical-align: initial;

            @include device-md {
                width: 25%;
            }

            &:first-child {
                width: 210px;
                padding-left: 0;

                @include device-md {
                    width: auto;
                }
            }
        }

        &:hover {
            background-color: transparent;
            box-shadow: none;
        }
    }
}

.pointer {
    cursor: pointer;
}

.table thead {
    tr {
        box-shadow: none;

        &:hover {
            box-shadow: none;
        }
    }
}

#child {
    margin-top: 60px;
    height: 75vh;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 10px;

    @media screen and (max-width:1350px) {
        height: 54vh;
        padding-bottom: 30px;
    }

    @media screen and (min-width:1351px) and (max-width:1650px) {
        height: 58vh;
        padding-bottom: 30px;
    }

    @include device-md {
        padding: 0 10px !important;
        margin-top: 50px !important;
    }

    @include device-xs {
        margin-top: 50px;
    }

    .tableFixHead {
        height: 50vh !important;
        max-height: 450px;
    }

    .add-new-userlist {
        .text-right {
            position: relative;
            background: $white;
            margin-bottom: 0 !important;
            padding-bottom: 1.7rem !important;
        }
    }
}

#contact {
    .tableFixHead {
        height: 60vh !important;
        max-height: 280px;


        table {
            margin-top: 0 !important;

            &.second-level {
                margin-bottom: 50px !important;
            }
        }
    }

    .text-right {
        position: relative;
        background: $white;
        margin-bottom: 0 !important;
        padding-bottom: 1.7rem !important;
    }
}

@-moz-document url-prefix() {
    html {
        scrollbar-width: none;
        scrollbar-color: transparent transparent;
    }
}

.tableFixHead {
    scrollbar-width: none;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 70vh;

    @include device-xls {
        height: 350px;
    }

    &.change-h {
        @include device-xls {
            height: 520px;
            padding-bottom: 100px;
        }
    }

    @include device-xxl-min {
        height: 74vh;
    }

    &::-webkit-scrollbar-track {
        margin-top: 0px;
    }

    &::-webkit-scrollbar {
        background: white;
    }

    table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0 4px;
        margin-bottom: 0;
        padding: 0 6px;

        table tr th {
            position: inherit !important;
        }
    }

    .table> :not(caption)>*>* {
        padding: 0.35rem 0.4rem;
    }

    thead {
        th {
            position: sticky !important;
            top: -6px !important;
            background: $background_set;
            font-size: $font-15;
            color: $dark-black1;
            font-family: $semibold-font;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            z-index: 8;

            @include device-xls {
                font-size: $font-15;

                &.hidden-set {
                    display: none;
                }

                @include device-xs {
                    font-size: $font-15;
                }
            }
        }

        td {
            padding: 8px 16px;
            color: $dark-black1;
            font-size: $font-16;

            @include device-xls {
                font-size: $font-15;

                &.table-width-response {
                    display: inline-block;
                    width: 180px;
                    white-space: nowrap;
                    overflow: hidden !important;
                    text-overflow: ellipsis;
                }

                @include device-xs {
                    font-size: $font-15;
                }
            }
        }
    }

    tbody tr td {
        font-size: $font-16;
        color: $dark-black1;
        vertical-align: middle;

        // white-space: nowrap;
        @include device-xls {
            &.hidden-set {
                display: none;
            }
        }

        @include device-xs {
            font-size: $font-15;
        }
    }

    @include device-lgb {
        .table-width-response {
            display: inline-block;
            width: 180px;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
            padding: 14px 5px !important;
        }
    }

    @include device-sm {
        .table-width-response {
            width: 100px;
        }
    }

    @include device-md {
        .table-width-response {
            width: 100px;
        }
    }

    .dropdown {
        .dropdown-toggle::after {
            display: none;
        }

        button {
            background: transparent;
            border: none;
        }

        .dropdown-menu {
            padding: 0 !important;
            border: none;
            box-shadow: 0 0 6px 0 rgb(0 0 0 / 12%);
            transform: translateX(-100%) !important;
            z-index: 0;

            @include device-sm {
                left: 0 !important;
            }

            a {
                cursor: pointer;
                font-family: $semibold-font;

                &:hover {
                    background: $bgcolor;
                }
            }
        }

        .dropdown-item.active,
        .dropdown-item:active {
            background-color: transparent;
        }
    }
}

app-booking-document-tab {
    .tableFixHead thead th {
        top: -10px !important;
    }
}

@include device-lg {
    .search-container {
        width: 30% !important;

        @include device-md {
            width: 100% !important;
            padding: 0;

            img.search {
                right: 1px !important;
            }
        }
    }
}

.update-status {
    margin-top: 100px;

    @media screen and (min-width:768px) and (max-width:991px) {
        margin: 10px auto !important;
    }

    @include device-md {
        padding: 0 !important;
        margin: 0;
    }

    &.set-padding-level {
        padding-top: 30px;
        margin-top: 0;

        @media screen and (min-width:768px) and (max-width:991px) {
            margin: 10px auto;
        }
    }

    app-account-list {
        .tableFixHead {
            @media screen and (min-width:1150px) and (max-width:1350px) {
                height: 62vh;

                @supports (-moz-appearance:button) and (contain:paint) {
                    height: 57vh !important;
                }
            }

            @media screen and (min-width:1351px) and (max-width:1600px) {
                height: 70vh;

                @supports (-moz-appearance:button) and (contain:paint) {
                    height: 65vh !important;
                }
            }
        }
    }

    .tableFixHead {

        &.user-tableFixHead {
            @media screen and (min-width:1150px) and (max-width:1350px) {
                height: 62vh !important;

                @supports (-moz-appearance:button) and (contain:paint) {
                    height: 57vh !important;
                }
            }

            @media screen and (min-width:1351px) and (max-width:1600px) {
                height: 70vh !important;

                @supports (-moz-appearance:button) and (contain:paint) {
                    height: 65vh !important;
                }
            }
        }

        &.workflow-tableFixHead {
            @media screen and (min-width:1150px) and (max-width:1350px) {
                height: 50vh !important;

                @supports (-moz-appearance:button) and (contain:paint) {
                    height: 35vh !important;
                }
            }

            @media screen and (min-width:1351px) and (max-width:1600px) {
                height: 55vh !important;

                @supports (-moz-appearance:button) and (contain:paint) {
                    height: 50vh !important;
                }
            }
        }

        @media screen and (min-height:750px) and (max-height:850px) {
            height: 62vh;

            @supports (-moz-appearance:button) and (contain:paint) {
                height: 50vh !important;
            }
        }

        &.change-h {
            @media screen and (min-height:750px) and (max-height:850px) {
                height: 77vh;
                padding-bottom: 20px;

                @supports (-moz-appearance:button) and (contain:paint) {
                    height: 56vh !important;
                }
            }

            &.action-list {
                height: 82vh !important;

                @media screen and (min-width: 1150px) and (max-width:1350px) {
                    height: 65vh !important;
                    padding-bottom: 0 !important;
                }

                @media screen and (min-width: 1351px) and (max-width:1650px) {
                    height: 73vh !important;
                    padding-bottom: 0 !important;
                }

                @media screen and (max-width:991px) {
                    height: 63vh !important;
                }
            }
        }

        @media screen and (min-height:851px) and (max-height:950px) {
            height: 70vh;

            @supports (-moz-appearance:button) and (contain:paint) {
                height: 52vh !important;
            }
        }

        @media screen and (min-height:951px) and (max-height:1050px) {
            height: 65vh;
        }

        @media screen and (min-height:1051px) {
            height: 68vh;
        }

        @-moz-document url-prefix() {
            @media screen and (min-height:951px) and (max-height:1050px) {
                height: 58vh !important;
            }

            @media screen and (min-height:1051px) {
                height: 61vh !important;
            }
        }

        &.search-results {
            @media screen and (min-height:750px) and (max-height:850px) {
                height: 62vh;
            }

            @media screen and (min-height:851px) and (max-height:950px) {
                height: 68vh;
            }

            @media screen and (min-height:951px) and (max-height:1050px) {
                height: 72vh;
            }

            @media screen and (min-height:1051px) {
                height: 75vh;
            }
        }
    }
}

@include device-md {
    .p-md-0 {
        padding: 5px;
    }
}

.table-sortable.background-white>thead>tr>th {
    background-color: $white;
}

.add-new-userlist {
    .tableFixHead thead th {
        background: $white;
    }
}

.ng-autocomplete {
    width: 100%;

    .autocomplete-overlay {
        z-index: 0 !important;
    }

    &.custom-style {
        &.ng-touched input {
            border: 2px solid $border-color;
        }

        span {
            font-family: $semibold-font;
            font-size: $font-16;
            letter-spacing: 0.32px;
            color: $dark-black1;
        }

        .autocomplete-container {
            &.active {
                z-index: 9;
            }

            .suggestions-container ul li a {
                &:hover {
                    background-color: $bgcolor;
                }
            }

            box-shadow: none;

            .input-container {
                input {
                    border: 2px solid $border-color;
                    border-radius: 5px;
                    color: $dark-black1;
                    height: 38px;
                    font-family: $regular-font;
                    font-size: $font-16;

                    &:focus {
                        border: 2px solid $blue-one;
                    }
                }

                .x i {
                    vertical-align: bottom;
                    padding-right: 18px;
                }
            }
        }
    }

    img.search {
        position: absolute;
        top: 10px;
        right: 14px;
        z-index: 99;
        background: $white;
        padding: 0 6px;
        cursor: pointer;

        @include device-lg {
            top: 5px;
            right: 16px;
            cursor: pointer;
            background: $white;
            padding: 4px;
            border-radius: 10px;
        }

        @include device-md {
            top: 4px;
            right: 4px !important;
            padding: 5px;
        }
    }
}

app-usermanagment-filter {
    .ng-autocomplete {
        img.search {
            right: 6px !important;
        }
    }

    .dropdown-menu.show {
        @media screen and (max-width:991px) {
            right: 0;
        }
    }
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: none !important;
}

ng-select {
    .add-user-popups.ng-select.ng-touched {
        border: solid 2px $blue-one;
    }

    .add-user-popups.profile-select-list.ng-select.ng-touched {
        border: solid 2px transparent;
    }
}

ng-select.add-user-popups {
    border-radius: 6px;
    color: $dark-black1;
    font-size: $font-16;
    border: 2px solid $border-color;
    padding: 6px 10px;
    margin: 30px 0 40px;

    @include device-sm {
        margin: 12px 0 16px;
    }

    ng-dropdown-panel {
        font-size: $font-16;
        color: $dark-black1;
        margin: 13px 2px 0;
        box-shadow: 1px 3px 10px 0 rgb(0 0 0 / 14%);
        background-color: $white;
        right: -3px;
        padding: 0;
        top: auto;
        line-height: 2;
        z-index: 9999;
    }

    >div {
        >div.single {
            >div.toggle {
                color: white !important;
                background: none;

                &:hover {
                    background-color: $yellow-two;
                }
            }

            >div.placeholder {
                color: $dark-black1;
                font-size: $font-16;
                font-family: $regular-font;
            }

            >div.clear {
                &:hover {
                    background-color: $yellow-two;
                }
            }
        }

        border: none !important;
    }

    .ng-select-container {
        height: 100%;
        max-height: 60px;
        color: rgba(0, 0, 0, 0.4);
        overflow: auto;

        &.ng-has-value {
            .ng-placeholder {
                display: none;
            }

            .ng-value {
                background-color: $bgcolor;
                font-family: $semibold-font;
                color: $text-color;
                letter-spacing: 0.16px;
                padding: 2px 10px;
                margin: 0 9px 4px 0;
                border-radius: 50px;
                height: 30px;

                @include device-sm {
                    font-size: $font-12;
                    padding: 2px 6px;
                    margin: 0 4px 4px 0;
                    height: 26px;
                }
            }

            .ng-value-icon.left {
                font-weight: 100;
                margin: 0 0 0 10px;
                float: right;
                position: relative;
                top: 4px;
                font-size: $font-20;
                cursor: pointer;
            }
        }
    }
}

.table-sortable>thead>tr>th {
    cursor: pointer;
    position: relative;
}

.table-sortable>thead>tr>th:after {
    content: " ↓";
    height: 10px;
    width: 11px;
    right: 10px;
    top: 16px;
    font-size: $font-14;
    color: transparent;
    margin-left: 4px;
    display: inline-block;
}

.sort-first:hover:after {
    visibility: hidden !important;
}

/* Default sortable indicator */

.sort-first {
    position: absolute;
}

.sort-first:after {
    content: " ↑" !important;
    color: $blue-one !important;
    visibility: visible;
}

.table-sortable>thead>tr>th.facive:after {
    // content: " \2191";
    color: $blue-one;
    visibility: hidden;
}

.table-sortable>thead>tr>th:first-child::after {
    visibility: visible;
}

.table-sortable>thead>tr>th {
    &.first-sec:after {
        visibility: visible;
    }
}

/* !Default */

/* Default sortable indicator:hover */

.table-sortable>thead>tr>th.asc:hover:after,
.table-sortable>thead>tr>th.desc:hover:after {
    // content: " \2191";
    color: $blue-one;
    visibility: visible;
    margin-left: 4px;
}

.table-sortable>thead>tr>th.desc:after:hover {
    content: " ↓";
    color: $blue-one;
    visibility: visible;
    margin-left: 4px;
}

.table-sortable>thead>tr>th.asc:after {
    // content: " ↑";
    content: " ↓";
    display: inline-block;
}

.table-sortable>thead>tr>th.desc:after {
    content: " ↑";
    display: inline-block;
    // content: " ↓";
}

/* !Default:hover */

.table-sortable>thead>tr>th.asc {
    &:hover:after {
        // content: " \2193";
        color: $blue-one;
    }

    &.sort:hover {
        &:after {
            content: "";
            background: url(/../../assets/img/sort.png) no-repeat;
            width: 11px;
            height: 11px;
            display: inline-block;
            color: transparent;
        }
    }

    &.sort.factive:hover:after {
        background: none;
        content: " ↓";
        margin-left: 4px;
        display: inline-block;
        color: $blue-one;
    }
}

.table-sortable>thead>tr>th.desc {
    &:hover:after {
        // content: " \2193";
        color: $blue-one;
    }

    &.sort:hover {
        &:after {
            content: "";
            background: url(/../../assets/img/sort.png) no-repeat;
            width: 11px;
            height: 11px;
            display: inline-block;
        }
    }

    &.sort.factive:hover:after {
        background: none;
        content: " ↑";
        display: inline-block;
        color: $blue-one;
    }
}

.dropdown-menu>ul>li>a.factive:hover:after {
    content: " \2193";
    color: $blue-one;
}

/* !Ascending */

/* Descending sortable indicator */

.table-sortable>thead>tr>th.factive:after {
    // content: " \2191";
    color: $blue-one;
    margin-left: 4px;
}

.Classic-Navy-color {
    color: $text-color !important;
}

.register-txt-align {
    position: relative;
    left: -13px;
}

.autocomplete-container .suggestions-container ul li a:hover {
    background-color: $bgcolor;
}

//user-management-style
app-usermanagment-list {
    .tableFixHead tbody tr td {
        height: 58px;
        vertical-align: middle;
    }
}

app-edoc-list {
    .tableFixHead {
        height: 80vh !important;

        @supports (-moz-appearance:button) and (contain:paint) {
            height: 75vh !important;
        }

        @media screen and (min-width:1351px) and (max-width:1600px) {

            @supports (-moz-appearance:button) and (contain:paint) {
                height: 75vh !important;
            }
        }

        @media screen and (min-width:1151px) and (max-width:1350px) {

            @supports (-moz-appearance:button) and (contain:paint) {
                height: 75vh !important;
            }
        }

        table {
            margin-bottom: 110px !important;

            @media screen and (min-width:1150px) and (max-width:1350px) {
                margin-bottom: 200px !important;
            }

            @media screen and (min-width:1351px) and (max-width:1600px) {
                margin-bottom: 170px !important;
            }
        }

        thead th {
            background: $background_set !important;
        }

        tbody tr td {
            height: auto !important;
        }
    }

}

.modal-backdrop {
    background-color: transparent;
}

.sticky-btn {
    right: 20px;
    z-index: 99;
}

.edoc-btn-sticky {
    position: fixed;
    right: 50px;
    z-index: 99;
}

.ng-select .ng-select-container .ng-value-container .ng-input>input {
    color: $dark-black1;
}

.close {
    opacity: 1;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option:hover {
    background: $bgcolor;
}

app-admin {
    .autocomplete-container .input-container .x {
        display: inline-block;
        right: 18px;

        i {
            color: $text-color;
            font-size: $font-20;
        }
    }
}

.autocomplete-container .input-container .x {
    display: none;
}

.autocomplete-container .suggestions-container {
    margin-top: 2px;
    z-index: 999 !important;
}

.autocomplete-container .suggestions-container ul {
    z-index: 3 !important;
}

.ng-select .ng-clear-wrapper {
    display: none;
}

.ng-value-icon {
    font-size: 25px;
    font-family: "titilliumWeb-light";
    line-height: 0.8;
    padding-right: 0px;
}

.shipment-txt {
    font-family: $light-font;
    color: $dark-black1;
}

.count-of-data {
    font-family: $semibold-font;
}

.mark-highlight {
    background: #b8eff9 !important;

    padding: 0;
    text-transform: initial;
}

.filter-selct-btn {
    background-color: $white;
    border-radius: 15px;
    height: 28px;
    margin-top: -3px;
    line-height: 0.8 !important;
    border: solid 2px $border-color;
    color: $dark-black1;
    padding: 2px 10px;
    font-size: $font-16;
    font-family: $semibold-font;
    z-index: 998;

    @media screen and(min-width:768px) and (max-width:991px) {
        min-width: 115px;
    }

    span {
        padding: 0px 5px;
    }
}


.dropdown.close-wrap.show {
    .filter-selct-btn {
        color: $text-color;
    }
}

.filter-selct-btn:focus {
    border-color: $blue-one;
    box-shadow: none;
}

@include device-lg {
    .tab-view {
        display: none;
    }
}

.profile-border {
    border-bottom: solid 1px $border-color;
    padding-top: 19px;
    width: 100%;
    margin-bottom: 10px;
}

.profile-btn-align {
    position: absolute;
    bottom: 0;

    @include device-sm {
        z-index: 999;
        right: 20px;
    }

    @include device-md {
        right: 30px;
    }
}

.profile-title {
    font-size: $font-18;
    color: $dark-black1;
    padding-top: 20px;
    text-transform: uppercase;
    font-family: $webbold-font;

    @include device-sm {
        font-size: $font-14;
        padding-top: 10px;
    }
}

.profile-subtitle {
    font-size: $font-18;
    color: $dark-black1;
    font-family: $webbold-font;
    text-transform: uppercase;

    @include device-sm {
        font-size: $font-16;
    }
}

.profile-value {
    font-size: $font-16;
    font-family: $semibold-font;
}

.profile-list label {
    font-size: $font-16;
    font-family: $regular-font;
    margin-bottom: 0px;

    @include device-sm {
        font-size: $font-12;
    }
}

.profile-list {
    margin-top: 20px;

    @include device-sm {
        margin-top: 10px;
    }
}

.profile-list div {
    font-size: $font-16;
    font-family: $semibold-font;

    @include device-sm {
        font-size: $font-14;
    }
}

.btn-yellow {
    background: $yellow;
    color: $text-color;
    text-transform: uppercase;
    padding: 10px 20px;
    border-radius: 6px;
    font-size: $font-16;
    border: solid 1px $yellow;
    font-family: $semibold-font;

    @include device-sm {
        font-size: $font-12;
        height: 39px;
        padding: 10px;
    }
}

.profile-user-btn {
    font-size: $font-14;

    @include device-sm {
        font-size: $font-12;
    }
}

.card-white {
    width: 100%;
    padding: 30px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);
    background: #fbfcfd;

    @include device-xs {
        padding: 15px;
    }

    p {
        @include device-sm {
            font-size: $font-14;
            color: $dark-black1;
        }
    }
}

button:focus {
    outline: none !important;
}

@include device-md {
    .child-account {
        padding: 0 !important;
    }
}

@include device-sm {
    .mobile-width {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 140px;
        display: inline-block;

        @include device-xs {
            width: 100px;
        }

        @include device-xss {
            width: 60px;
        }
    }
}

.card-inner-width {
    width: 350px;
}

.newuser-toast-txt {
    font-size: $font-16;
    color: $text-color;
    font-family: $semibold-font;

    @include device-sm {
        font-size: $font-12;
        text-align: left;
        line-height: 1.4;
    }

    .visit-shipmt {
        color: $text-color;
    }

}

.btn-white {
    background: $white;
    color: $text-color;
    text-transform: uppercase;
    padding: 10px 20px;
    border-radius: 6px;
    font-size: $font-16;
    border: solid 1px $yellow;
    font-family: $semibold-font;

    @include device-sm {
        font-size: $font-12;
        height: 39px;
    }
}

html,
body {
    height: 100%;
}

#account-table,
#user-table {
    margin-bottom: 100px;

    .tableFixHead thead th {
        font-size: $font-15 !important;
    }

    @include device-md {
        margin-bottom: 0;
    }
}


.tableFixHead #user-table td.table-size.change-li {
    @media screen and (min-width:1025px) and (max-width:1500px) {
        word-break: break-all;
    }
}

::ng-deep {

    table#user-table,
    table#userAccountTable {
        .table-hover>tbody>tr:hover>* {
            --bs-table-accent-bg: $white !important;
        }
    }

    .table-hover>tbody>tr:hover>* {
        --bs-table-accent-bg: $white !important;
    }

    app-shipment-documents-tab {
        .tableFixHead thead th {
            top: -10px !important;
        }
    }

    app-usermanagment-detail {
        .add-one {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 150px;
            display: inline-block;
        }
    }

    #globalsearch-result #faq .card .card-body #custom-sec-id .shipment-ref-no {
        .mobile-remove {
            display: none !important;
        }
    }

    exploration-footer-modern {
        .explorationNavigationContent.reading.unselectable {
            position: absolute !important;
            top: 0 !important;
        }
    }
}

ngb-datepicker-navigation-select>.custom-select {
    padding: 0px !important;

    &:focus {
        position: relative;
        left: -10px;
        text-align: center;
    }
}

.table> :not(caption)>*>* {
    box-shadow: none;
}

app-global-search-list,
app-booking-global-search {
    .infiniteScroll {
        height: auto !important;
    }
}

#shipment-sec-id {
    width: 100%;
}

.my-profile-toast.set-alignment-issue {
    .newuser-toast-bg {
        top: 70px;
    }

    &.save-message {
        .newuser-toast-bg {
            top: 30px;
        }
    }
}

#dashboard-shipment {
    #shipment-sec-id {
        height: 100% !important;
    }

    .progress-icon-ctr {
        right: 28px;
    }
}

#dashboard-shipment {
    .custom-list-ctr {
        .progress-icon-ctr {
            right: -26px;
        }

        .shiping-list-card {
            .dropdown-section {
                top: -20px;
            }
        }
    }
}

.my-profile {
    .my-profile-toast {
        .newuser-toast-bg {
            top: 0 !important;

            @include device-sm {
                top: -34px !important;
            }

            position: absolute;
        }
    }
}

#wrap-transport button.dropdown-item:hover,
#wrap-transport button.dropdown-item:focus,
#button-transport:hover,
#button-transport:focus,
#wrap-statuscustom button:hover,
#wrap-statuscustom button:focus,
#dropdownMenuButton:hover,
#dropdownMenuButton:focus,
#locationFilter-shipper:hover,
#locationFilter-shipper:focus,
#locationFilter-consignee:hover,
#locationFilter-consignee:focus,
#dateRangeButton1:hover,
#dateRangeButton1:focus,
#button-exception:hover,
#button-exception:focus {
    border: 2px solid $blue-one;
}

.border-added[aria-expanded="true"] {
    border: 2px solid $blue-one;
    color: $text-color;
}

.table {

    #dropdownMenuButton:hover,
    #dropdownMenuButton:focus {
        border: none !important;
    }
}

.right-nav .status-update .dropdown button:hover,
.profile-context-nav #dropdownMenuButton:hover {
    border: none !important;
}

ngb-datepicker {

    .ngb-dp-day,
    .ngb-dp-weekday,
    .custom-day {
        height: auto !important;
    }
}

.data-soures {
    ngb-datepicker {
        .ngb-dp-day {
            width: 28px;
        }

        .ngb-dp-weekday {
            width: 28px;
        }
    }
}

.head-sec strong {
    font-family: $semibold-font;
}

.saved-filters {
    margin-bottom: 150px;
}

.contain-top>.row {
    margin-bottom: 150px;

    @include device-md {
        margin-bottom: 10px;
    }

    @include device-sm {
        margin-bottom: 50px;
    }
}

.contain-top.dashboard-rap>.row {
    margin-bottom: 150px;

    @media screen and (min-width:1151px) and (max-width:1350px) {
        margin-bottom: 40px;

        @supports (-moz-appearance:button) and (contain:paint) {
            margin-bottom: 105px !important;
        }
    }

    @media screen and (min-width:1351px) and (max-width:1800px) {
        margin-bottom: 30px;
    }

    @media screen and (min-width:1801px) {
        margin-bottom: 60px;
    }

    @include device-md {
        margin-bottom: 10px;
    }

    @include device-sm {
        margin-bottom: 50px;
    }
}

.shipment-details.shipmet-fullwidth {
    .header-fix.fixed-fullwidth .head-sec .left-sec .detail-shipment p.ref-width {
        span.data-ref {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 90%;
            display: inline-block;
            position: absolute;

            @include device-md {
                width: 350px;
            }

            @include device-sm {
                width: 300px;
            }
        }
    }

    #faq {
        margin-top: 0 !important;

        .card {
            margin: 10px 5px 15px !important;
        }
    }

    .custom-title-message {
        margin: 15px 0 0;
        padding: 0;
        width: 65%;
        position: relative;
        top: 30px;
        left: 30px;

        @include device-sm {
            margin: 15px 0 0 10px;
        }

    }

    #home1 {
        padding-top: 55px;

        @media screen and (min-width:1150px) and (max-width: 1350px) {
            height: 65vh;
        }

        @media screen and (min-height: 550px) and (max-height: 600px) {
            height: 40vh !important;
        }
    }

    #logs1 {
        margin-top: 30px;
    }

    .newuser-toast-bg.add-remove-toast .contain-bg {
        @include device-sm {
            top: -23px;
        }
    }

    .newuser-toast-bg {
        @include device-md {
            top: 22px !important;
        }

        @include device-sm {
            position: relative;
        }

        &.full-ship-toast {

            top: 90px;

            @include device-md {
                top: 5px !important;
                position: relative;
            }

            @include device-xs {
                top: 5px !important;
            }

            .contain-bg {
                top: 2px !important;

                @include device-sm {
                    top: 10px;
                }
            }

            &.shipment-full-ship-toast {
                @include device-md {
                    top: 10px !important;
                }

                @include device-sm {
                    top: 0px !important;
                }
            }

            &.booking-login,
            &.shipment-login {
                @include device-md {
                    width: auto !important;
                    top: 15px !important;
                }

                @include device-sm {
                    top: 5px !important;
                }

                .contain-bg {
                    .newuser_link {
                        @include device-md {
                            width: auto !important;
                        }
                    }

                    .close-button {
                        @include device-md {
                            top: 5px;
                        }
                    }

                }
            }

            &.shipment-login {
                .contain-bg {
                    @media screen and (min-width:576px) and (max-width:991px) {
                        width: auto !important;
                    }

                    .close-button {
                        @media screen and (min-width:576px) and (max-width:991px) {
                            position: initial;
                            margin-left: 20px;
                            padding-right: 0;
                        }
                    }
                }
            }
        }

    }

    .contain-bg {
        @include device-md {
            top: 3px;

            @include device-sm {
                top: -44px;
            }
        }

        &.upload-booking-toast {
            @include device-sm {
                top: 0px;
                width: 97% !important;
            }
        }
    }

    .add-remove-toast {
        .contain-bg {
            position: relative;
            top: 15px;

            @include device-md {
                top: -10px;
            }
        }
    }

    #logs {
        padding: 60px 0 100px !important;

        @include device-md {
            padding: 10px 0 100px !important;
        }
    }

    #child {
        padding-top: 10px !important;
    }

    #contact {
        padding-top: 60px;

        @include device-md {
            margin-top: 70px !important;
        }

    }

    #profile {
        .tableFixHead td span.add-middle-dots {
            overflow: initial !important;
            white-space: normal;
            text-overflow: unset;
            position: relative;
            display: inline-block;
        }
    }
}

#profile {
    &.overall-scroll {
        height: 80vh;
        overflow-y: scroll;
        margin-top: 20px;
        padding-top: 10px;

        @include device-md {
            height: auto;
        }

        >div {
            height: 80vh;
            overflow-y: scroll;
            margin-top: 20px;

            @include device-md {
                height: auto;
                overflow-y: initial;
            }
        }

        .tableFixHead {
            height: auto !important;
            overflow: auto;
            width: 100%;
            margin: 0 !important;

            @include device-md {
                width: 100%;
            }

            @include device-sm {
                width: 99%;
            }
        }
    }

    .upload-files-ctr-overall {
        margin-top: 20px;

        @media screen and (min-width:768px) and (max-width:991px) {
            margin-top: 0;
        }

        @media screen and (min-width:576px) and (max-width:767px) {
            margin-top: 20px !important;
        }

        @include device-xs {
            margin-top: 20px;
        }
    }
}

#profile1 {
    &.overall-scroll {
        height: 75vh;
        overflow-y: scroll;
        margin-top: 30px !important;

        @include device-md {
            margin-top: 100px !important;
            height: auto;
            overflow-y: auto;
        }

        @include device-sm {
            width: 100%;
            margin-bottom: 60px;
            margin-top: 60px !important;
        }

        .tableFixHead {
            height: auto !important;
            overflow: auto;
            width: 100%;
            margin-top: 50px;

            @include device-md {
                width: 100%;
            }

            @include device-sm {
                width: 100%;
            }

            thead {
                th {
                    @include device-md {
                        font-size: $font-15 !important;
                    }
                }
            }

            td {
                span {
                    font-size: $font-15 !important;
                }
            }
        }

        .upload-table-fix+.tableFixHead {
            margin-top: 0;
        }

        .files-list:last-child {
            margin-bottom: 30px;
        }

        .files-list {
            @include device-sm {
                width: 95%;
            }

            @include device-xs {
                width: 100%;
            }

            @include device-xss {
                width: 90%;
            }
        }

        .upload-doc-ctr {
            @include device-sm {
                max-width: 100%;
                left: 0 !important;
            }

            @include device-xss {
                max-width: 92%;
            }
        }

        .upload-btn {
            @include device-xs {
                right: 5px;
            }
        }
    }
}

.upload-doc-ctr {
    .input-container {
        input {
            padding: 13px 15px !important;
            height: 50px !important;
            font-size: $font-16 !important;
            font-family: $webbold-font;
            font-weight: 600;
            color: $dark-black1 !important;

            &::placeholder {
                font-family: $webbold-font;
                font-weight: 600 !important;
                opacity: 60%;
            }
        }
    }
}

/*login changes*/
.login-ctr {
    height: 100%;
    position: inherit !important;
    overflow: hidden !important;

    .login-ctr-inner {
        position: absolute;
        left: 50%;
        top: 49.5%;
        transform: translate(-50%, -50%);

        @media screen and(max-height:800px) {
            top: 50%;
        }

        @media screen and(max-height:400px) {
            top: 70%;
        }
    }

    @media screen and(max-height:800px) {
        height: 110vh;
    }
}

#logs1 {
    @include device-md {
        margin-top: 130px;
    }

    .logs {
        .card {
            .card-body {
                padding: 0 1rem 1rem;
            }
        }
    }

    .tableFixHead {
        thead {
            th {
                padding-top: 30px !important;
            }
        }
    }
}

.login-hr {
    border-top: 1px solid $border-color;
    width: 537px;
    margin: 50px 0;
    opacity: initial;
    background-color: initial;

    @media screen and (max-height: 800px) {
        margin: 25px 0;
    }

    @media screen and (max-height: 650px) {
        margin: 15px 0;
    }

    @include device-xls {
        width: 460px;
    }

    @include device-md {
        width: 400px;
    }

    @include device-sm {
        width: 335px;
    }
}

.outer-close-set {
    display: inline-block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 9999;
}

.schedule-left-ctr {

    .date-ctr {

        .ngb-dp-header,
        .ngb-dp-weekdays {
            background: $white;
        }

        .ngb-dp-week-number,
        .ngb-dp-weekday {
            font-style: normal;
            color: $dark-black1;
            text-transform: uppercase;
        }

        .ngb-dp-day,
        .ngb-dp-week-number,
        .ngb-dp-weekday {
            width: 33px;
        }

        .custom-select {
            background: none;
            border: 0;
            text-align: center;
            width: 55px;
            flex: 0 1 auto;
        }

        .ngb-dp-weekdays {
            border-bottom: 1px solid $border-color;
            border-top: 1px solid $border-color;
        }

        .ngb-dp-navigation-select {
            flex: 1 1 2rem;
            margin-left: 25%;

            &:focus {
                box-shadow: initial;
            }
        }

        .ngb-dp-arrow {
            position: absolute;
            top: 50%;
            left: 3%;

            &.right {
                right: 3%;
                left: initial;
            }

            .btn-link {
                color: $dark-black1;

                &:focus {
                    box-shadow: initial;
                }
            }
        }

        .ngb-dp-navigation-chevron {
            border-width: 0.15em 0.15em 0 0;
            width: 0.6em;
            height: 0.6em;
        }

        .btn-light {
            color: $dark-black1;

            &:focus,
            &:hover {
                background: $bgcolor !important;
                color: $dark-black1 !important;
                border-radius: 50%;

            }

            &.bg-primary {
                &.text-white {
                    background: $bgcolor !important;
                    color: $dark-black1 !important;
                    border-radius: 50%;

                    &:focus,
                    &:hover {
                        background: $bgcolor !important;
                        color: $dark-black1 !important;
                        border-radius: 50%;
                    }
                }
            }
        }

        input {
            padding-left: 36px !important;
        }

        .chevron.ngb-tp-chevron,
        button.btn.btn-link {
            display: block;
        }
    }

}

ngb-datepicker ngb-datepicker-month .ngb-dp-weekday {
    display: inline-block !important;
}

.modal {
    z-index: 9999;
}

app-account-list {
    .modal-dialog {
        @include device-md {
            top: 60px;
        }
    }
}

.shipmet-fullwidth.shipment-details .newuser-toast-bg.page-refreshed {
    top: 70px !important;
}

.top-set-toastmessage {
    .admin-top-sm {
        padding-top: 80px;
    }
}

.toasterMsg {

    app-user-management,
    app-account-list {
        .modal-dialog {
            @include device-md {
                top: 100px !important;
            }
        }
    }

    .dimScreen {
        .shipment-details {
            @include device-md {
                margin-top: 50px !important;
            }
        }
    }

    .header-fix {
        .head-sec {
            &.set-one {
                @include device-md {
                    margin-top: 10px !important;
                }
            }
        }

        &.fixed-fullwidth {
            @include device-md {
                top: 80px !important;
            }

            @include device-xs {
                position: relative;
                top: 60px !important;
            }
        }
    }

    .shipmet-fullwidth {

        .header-fix {
            .head-sec {
                &.set-one {
                    @include device-md {
                        margin-top: 70px !important;
                    }
                }
            }

            .newuser-toast-bg {
                &.add-remove-toast {
                    &.toast-limited-acess {
                        top: 52px !important;

                        @include device-md {
                            position: relative;
                            top: 70px !important;
                        }
                    }
                }
            }
        }
    }

    .shipment-details.shipmet-fullwidth {
        .newuser-toast-bg.full-ship-toast {
            top: 150px;
        }

        .add-remove-toast .contain-bg.pge-rfresh,
        .remove-added-toast.full-ship-toast .contain-bg {
            top: 50px !important;
        }

        .toast-limited-acess.page-refreshed .contain-bg.pge-rfresh {
            top: 90px !important;
        }
    }

    .faq-list-set {
        .faq-accordion-ctr {
            height: 75vh !important;

            @media screen and (max-height: 900px) {
                height: 58vh !important;
            }

            @media screen and (max-height: 750px) {
                height: 65vh !important;
            }

            @media screen and (max-height: 650px) {
                height: 50vh !important;
            }

            @media screen and (max-width: 575px) and (max-height: 900px) {
                height: 61vh !important;
            }

            @media screen and (max-width: 575px) and (max-height: 750px) {
                height: 56vh !important;
            }

            @media screen and (max-width: 575px) and (max-height: 700px) {
                height: 51vh !important;
            }

            @-moz-document url-prefix() {
                height: 50vh !important;

                @media screen and (max-height: 950px) {
                    height: 45vh !important;
                }

                @media screen and (max-height: 850px) {
                    height: 41vh !important;
                }

                @media screen and (max-height: 750px) {
                    height: 35vh !important;
                }

                @media screen and (max-height: 650px) {
                    height: 26vh !important;
                }

                @media screen and (max-height: 600px) {
                    height: 22vh !important;
                }
            }
        }

        @media only screen and (-webkit-min-device-pixel-ratio: 1) {

            ::i-block-chrome,
            .faq-accordion-ctr {
                height: 60vh;

                @media screen and (max-height: 900px) {
                    height: 50vh;
                }

                @media screen and (max-height: 650px) {
                    height: 40vh;
                }
            }
        }
    }

    .newuser-toast-bg {
        &.add-remove-toast {
            &.toast-limited-acess {
                .contain-bg {
                    top: 5px !important;

                    @include device-sm {
                        top: -18px !important;
                    }

                    @media screen and (min-width:992px) and (max-width: 1199px) {
                        top: -32px !important;
                    }

                    @media screen and (min-width:1200px) and (max-width: 1450px) {
                        top: -20px !important;
                    }
                }
            }
        }
    }

    .dimScreen .shipmet-fullwidth .header-fix.fixed-fullwidth {
        @media screen and (min-width:992px) and (max-width: 1450px) {
            top: 50px;
        }
    }

    .newuser-toast-bg.add-remove-toast.toast-limited-acess .contain-bg.pge-rfresh {
        @media screen and (min-width:1280px) {
            top: 55px !important;
        }
    }

    .newuser-toast-bg.page-refreshed {
        @media screen and (min-width:1280px) {
            top: 55px !important;
        }
    }

    app-shipment {
        .rap-set {
            &.need-help {
                top: 180px;
                left: 190px;
            }

            &.dynamicRapClass1 {
                top: 270px;
            }

            &.dynamicRapClass2 {
                top: 305px;
            }

            &.dynamicRapClass5 {
                top: 285px;
            }

            &.dynamicRapClass6 {
                top: 130px;
                right: 206px;
            }

            &.dynamicRapClass7 {
                top: 300px;
            }

            &.dynamicRapClass8 {
                top: 280px;
            }
        }
    }

    app-booking {
        .rap-set {
            &.need-help {
                top: 220px;
            }

            &.dynamicRapClass1 {
                top: 270px;
            }

            &.dynamicRapClass2 {
                top: 315px;
            }

            &.dynamicRapClass5 {
                top: 285px;
            }

            &.dynamicRapClass6 {
                top: 280px;
                left: 250px;
            }

            &.dynamicRapClass7 {
                top: 300px;
            }
        }
    }

    app-customs {
        .rap-set {
            &.dynamicRapClass2 {
                top: 320px;

                @media screen and (min-width: 1500px) {
                    top: 330px;
                }
            }
        }
    }

    .flter-dateRange {
        @-moz-document url-prefix() {
            @media screen and (max-height: 500px) {
                height: 260px;
                overflow: scroll;
            }
        }
    }

    .shipmet-fullwidth.shipment-details .newuser-toast-bg.page-refreshed {
        top: 130px !important;

        @media screen and (min-width:1150px) and (max-width:1600px) {
            top: 155px !important;
        }
    }

    .workflow-toast {
        .newuser-toast-bg {
            top: 65px !important;
        }
    }

    .data-field-form-ctr {
        &.data-field-form-ctr-dfm {
            height: 72vh;

            @media screen and (min-width:1150px)and (max-width:1350px) {
                height: 56vh;
            }

            @media screen and (min-width:1351px)and (max-width:1600px) {
                height: 66vh;
            }

            @media screen and (min-width:1900px) {
                height: 76vh;
            }
        }
    }

    .data-field-form-ctr.workflow-form-ctr {
        height: 71vh;

        @media screen and (min-width:1150px)and (max-width:1350px) {
            height: 62vh;
        }

        @media screen and (min-width:1900px) {
            height: 80vh;
        }
    }

    .data-field-form-ctr.workflow-form-ctr.date-range-control-dfd {
        height: 64vh;

        @media screen and (min-width:1150px)and (max-width:1350px) {
            height: 52vh;
        }

        @media screen and (min-width:1351px)and (max-width:1600px) {
            height: 64vh;
        }

        @media screen and (min-width:1900px) {
            height: 71vh;
        }
    }

    .action-field-footer {
        bottom: 120px !important;
    }

    .action-field-mgmnt-ctr {
        height: 72vh !important;

        @media screen and (min-width: 1351px) and (max-width: 1650px) {
            height: 60vh !important;
        }

        @media screen and (min-width: 1150px) and (max-width: 1350px) {
            height: 50vh !important;
        }
    }
}

.new-close-toast {
    display: none;
}

app-action-field-detail-tab {
    .newuser-toast-bg {
        img {
            display: none;
        }

        img.new-close-toast {
            display: inline-block;
        }

        .contain-bg {
            border-radius: 5px;
            padding-right: 0;
        }
    }

    .update-status .ng-star-inserted .tableFixHead.data-workflow-list-ctrl {

        @media screen and (min-width:1150px) and (max-width: 1350px) {
            height: 36vh !important;
        }

        @media screen and (min-width:1351px) and (max-width: 1699px) {
            height: 52vh !important;
        }

        @media screen and (min-width:1700px) {
            height: 66vh !important;
        }
    }

}

.dimScreen {
    &.shipment-fullwidth {
        .shipment-details {
            @include device-md {
                margin-top: 40px !important;
            }
        }
    }

    .shipmet-fullwidth {
        .header-fix {
            &.fixed-fullwidth {
                top: 25px;
            }
        }

        .tab-content {
            >div {
                @include device-md {
                    margin: 100px 0 0 !important;
                }
            }
        }
    }
}

#remove-Module-popup {
    z-index: 99999;
}

#logs {
    height: 75vh;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 20px 0 100px;
    width: 100%;

    @include device-md {
        width: 100%;
        overflow: visible;
    }

    @include device-sm {
        padding: 0;
    }

    .logs {
        .card {
            .card-bod {
                padding: 0 1rem 1rem;
            }
        }
    }

    .tableFixHead {
        thead {
            th {
                padding-top: 30px !important;
            }
        }
    }

}

.shipment-fullwidth {
    .header-fix {
        &.fixed-fullwidth {
            .head-sec {
                .left-sec {
                    .detail-shipment {
                        p.ref-width {
                            width: 755px !important;

                            @include device-sm {
                                width: 400px !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

#scroll-table-added {
    padding-right: 1rem;
    height: auto;

    &::-webkit-scrollbar {
        display: inline-block;
        width: 4px;
        border-radius: 5px;
        background: #d8d9d9;

        @include device-md {
            display: none;
        }
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #d8d9d9;
        border-radius: 5px;
        margin-top: 60px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $blue-two;
        margin-top: 60px;
    }
}

.table-view-ctr {
    &::-webkit-scrollbar:horizontal {
        display: inline-block;
        height: 10px;
        width: 10px;
        margin-top: 10px;
    }

    &::-webkit-scrollbar-track {
        background: #ddd;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #ddd;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: $blue-three;
    }

    &::-webkit-scrollbar-thumb {
        background: $blue-three;
        border-radius: 10px;
    }
}

#previewModule,
#previewFullview {
    #scroll-table-added {
        padding-right: 0;
        height: 65vh !important;
        position: relative;
        left: -6px;

        &::-webkit-scrollbar {
            display: none;
        }

        @media screen and (min-width:1150px) and (max-width: 1350px) {
            height: 42vh !important;
        }

        @media screen and (min-width:1351px) and (max-width: 1650px) {
            height: 50vh !important;
        }

        @include device-sm {
            height: 75vh !important;

            @media only screen and (max-height: 800px) {
                height: 68vh !important;
            }
        }
    }
}

app-booking-details {
    #accordion {
        margin-top: 40px;
    }
}

app-booking {
    .shipmet-title span img {
        right: -34px !important;

        @include device-xl {
            right: -12px !important;
        }
    }

    .rap-set.need-help {
        @include device-xl {
            left: 170px !important;
        }
    }
}

app-shipment {
    .shipmet-title span img {
        right: -60px !important;
        top: 6px;

        @include device-xl {
            right: -36px !important;
        }
    }
}

#accordion {
    height: 70vh;
    overflow: scroll;
    padding-bottom: 100px;
}

@include device-sm {
    app-booking-detail-tab>div {
        margin-top: 20px;
    }
}

.shipmet-fullwidth {
    .header-fix {
        .head-sec {
            &.set-one {
                margin-top: 0 !important;

                @include device-md {
                    top: -10px !important;
                }
            }
        }
    }
}

@media screen and (max-width: 440px) {

    #logs,
    #logs1 {
        .tableFixHead {
            thead {
                th {
                    vertical-align: top;
                }
            }

            .document-sec {
                thead {
                    th {
                        &:nth-child(2) {
                            width: 105px !important;
                        }
                    }

                }

            }
        }

        .table-sortable>thead>tr> {

            th#time-date-h1:after,
            th#time-date-h2:after,
            th#time-date-h3:after,
            th#time-date-h4:after {
                position: absolute;
                right: 30px;
                top: 30px;
            }
        }

        #headingThree {
            .table-sortable>thead>tr> {

                th#time-date-h1:after,
                th#time-date-h2:after,
                th#time-date-h3:after,
                th#time-date-h4:after {
                    right: 10px;
                }
            }
        }
    }

    .mble-dt-fix {
        width: 85px;
        display: inline-block;
    }
}

.refresh-icon {
    border-color: transparent $orange-color;
    display: inline-block;
    position: absolute;
    left: 6px;
    margin-top: 4px;
    -webkit-animation: rotate-icon 1.8s linear infinite;
    animation: rotate-icon 1.8s linear infinite;
    border-radius: 50%;
    border-style: solid;
    border-width: 0.125em;
    height: 1.1em;
    width: 1.1em;

    &:before,
    &:after {
        border-style: solid;
        content: "";
        display: block;
        position: absolute;
        width: 0;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }

    &:after {
        border-color: transparent transparent transparent $orange-color;
        border-width: 0.3125em 0 0.3125em 0.3em;
        top: -0.3125em;
        left: 0.0625em;
    }

    &:before {
        border-color: transparent $orange-color transparent transparent;
        border-width: 0.3125em 0.3em 0.3125em 0;
        bottom: -0.3125em;
        right: 0.0625em;
    }
}

@-webkit-keyframes rotate-icon {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes rotate-icon {
    100% {
        transform: rotate(360deg);
    }
}

//road-map
#search-result-customs {
    .single-row .mobile-remove {
        display: none !important;
    }

    .custom-list-ctr .pga-exists {
        margin-left: 2px;
        margin-right: -6px;
    }

    .booking-road-map {
        &.customs-road-map {
            .road-maps {
                .rap {
                    display: none;
                }

                li:first-child .rap,
                li:last-child .rap {
                    display: inline-block;

                    h6 {
                        width: 100% !important;
                        text-overflow: unset;
                    }
                }

                li:last-child .rap {
                    width: 150px;
                    right: 0;
                    left: -140px;

                    h6 {
                        text-overflow: unset;
                    }

                    p {
                        width: auto;
                    }
                }
            }
        }
    }

    .progress-icon-ctr {
        top: 10px;
        left: 80px;
    }

    .rap-sec {
        width: 78% !important;
    }
}

.booking-road-map {
    &.customs-road-map {
        .road-maps {
            width: 100%;

            @include device-lg {
                width: 94%;

                @include device-md {
                    width: 90%;
                    position: relative;
                    top: 10px;
                }
            }

            @media screen and (max-width: 991px) {
                width: 85%;
            }

            @media screen and (max-width: 900px) {
                width: 82%;
            }

            @media screen and (max-width: 760px) {
                width: 75%;
            }

            @media screen and (max-width: 540px) {
                width: 70%;
            }

            @media screen and (max-width: 450px) {
                width: 62%;
            }

            .timeline {
                counter-reset: test 20;
                display: flex;
                width: 100%;
                margin: 0;
                padding: 0;
                position: relative;
                z-index: 9;
                left: 8px;

                @include device-md {
                    top: -10px;
                    left: 0;
                }

                li {
                    list-style: none;
                    display: flex;
                    width: 100%;
                    position: relative;
                    text-transform: uppercase;

                    &:after {
                        content: "";
                        width: 100%;
                        border-bottom: 3px solid #d7e4ea;
                        position: absolute;
                        top: 2px;
                        left: 4px;

                        @include device-md {
                            top: 2px;
                        }
                    }

                    &::before {
                        content: "";
                        background-color: $text-color;
                        color: #f1f1f1;
                        width: 6px;
                        height: 6px;
                        border-radius: 50%;
                        box-shadow: 0 0 0px #e5ecf2 inset, 0 0 0px 6px #e5ecf2, 0 0 8px #e5ecf2;
                        -webkit-box-shadow: 0 0 0px #e5ecf2 inset, 0 0 0px 6px #e5ecf2, 0 0 8px #e5ecf2;
                        -moz-box-shadow: 0 0 0px #e5ecf2 inset, 0 0 0px 6px #e5ecf2, 0 0 8px #e5ecf2;
                        position: absolute;
                        left: 0;
                        top: 0;
                        z-index: 2;
                    }

                    &.no-circle+.no-circle {
                        &::before {
                            background-color: #d7e4ea;
                            box-shadow: none;
                            width: 8px;
                            height: 8px;
                        }
                    }

                    &:nth-last-child(2),
                    &:first-child {
                        width: 280px;

                        @include device-md {
                            width: 98%;
                        }

                        @media screen and (min-width:1151px) {
                            width: 50%;
                        }

                        .rap {
                            h6 {
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                                width: 120px;

                                @include device-md {
                                    width: 390px;
                                }

                                @include device-sm {
                                    width: 285px;
                                }

                                @include device-xs {
                                    width: 190px;
                                }

                                @-moz-document url-prefix() {
                                    height: 20px;
                                }

                                &:empty {
                                    @-moz-document url-prefix() {
                                        height: 0;
                                    }
                                }

                                @media screen and (min-width:992px) and (max-width:1299px) {
                                    width: 75px;
                                }

                                @media screen and (min-width:1300px) and (max-width:1500px) {
                                    width: 75px;
                                }

                                @media screen and (min-width:1501px) {
                                    width: 140px;
                                }

                                @media screen and (min-width:1200px) {
                                    width: 100% !important;
                                }
                            }
                        }
                    }

                    &:first-child {
                        .rap {
                            left: 0 !important;
                            text-align: left !important;

                            @media screen and (min-width:1025px) and (max-width: 1200px) {
                                width: 100%;
                            }

                            @media screen and (min-width:992px) and (max-width: 1024px) {
                                width: 50%;
                            }

                            @include device-md {
                                display: inline-block;
                            }

                            @media screen and (min-width:1200px) {
                                width: 100% !important;
                            }

                            h6 {
                                @media screen and (min-width:992px) and (max-width:1299px) {
                                    width: 100% !important;
                                }

                                @media screen and (min-width:1200px) {
                                    width: 47% !important;
                                }
                            }
                        }

                        &::before {
                            display: inline-block;
                        }

                    }

                    &:last-child {
                        width: 0;

                        &:after {
                            display: inline-block;
                        }

                        .rap {
                            left: -110px;
                            width: 110px;
                            text-align: right;

                            @media screen and (min-width:992px) and (max-width:1299px) {
                                left: -65px;
                            }

                            @media screen and (min-width:1300px) and (max-width:1500px) {
                                left: -70px;
                                width: auto;
                            }

                            @include device-md {
                                display: inline-block;
                            }

                            @media screen and (min-width:1450px) {
                                left: -145px;
                                width: 150px;
                            }

                            @media screen and (min-width:1600px) {
                                left: -175px;
                                width: 180px;
                            }

                            @media screen and (min-width:1850px) {
                                left: -195px;
                                width: 200px;
                            }

                            @media screen and (min-width:2000px) {
                                left: -265px;
                                width: 270px;
                            }

                            @media screen and (min-width:2600px) {
                                left: -365px;
                                width: 370px;
                            }

                            @media screen and (min-width:3000px) {
                                left: -465px;
                                width: 470px;
                            }


                            h6,
                            p {
                                width: 120px;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;

                                @media screen and (min-width:992px) and (max-width:1299px) {
                                    width: 70px;
                                }

                                @media screen and (min-width:1300px) and (max-width:1500px) {
                                    width: 75px;
                                }

                                @media screen and (min-width:1450px) {
                                    width: 150px;
                                }

                                @media screen and (min-width:1600px) {
                                    width: 180px;
                                }

                                @media screen and (min-width:1850px) {
                                    width: 200px;
                                }

                                @media screen and (min-width:2000px) {
                                    width: 270px;
                                }

                                @media screen and (min-width:2600px) {
                                    width: 370px;
                                }

                                @media screen and (min-width:3000px) {
                                    width: 470px;
                                }
                            }
                        }

                        &::before {
                            display: inline-block;
                        }
                    }

                    &:nth-last-child(2) {
                        .rap {
                            left: -90px;
                            text-align: center;

                            @-moz-document url-prefix() {
                                left: -65px;
                            }

                            @media screen and (min-width:992px) and (max-width:1299px) {
                                left: -60px !important;
                            }

                            @media screen and (min-width:1300px) and (max-width:1500px) {
                                left: -60px;
                            }

                            @include device-lg {
                                left: -120px;
                            }

                            @media screen and (min-width:1025px) and (max-width:1200px) {
                                display: none;
                            }

                            @media screen and (min-width: 1200px) {
                                width: 80%;
                                left: -50%;
                            }

                            @-moz-document url-prefix() {
                                @media screen and (min-width: 1200px) {
                                    width: 81%;
                                }
                            }

                            h6 {
                                width: 100px;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;

                                @include device-lg {
                                    width: 130px;
                                }

                                @include device-md {
                                    width: 80px !important;
                                }

                                @media screen and (min-width:992px) and (max-width:1299px) {
                                    width: 120px;
                                }

                                @media screen and (min-width:1300px) and (max-width:1500px) {
                                    width: 130px;
                                }

                                @media screen and (min-width:1501px) {
                                    width: 200px;
                                }
                            }
                        }


                        h6 {
                            @media screen and (min-width:1900px) {
                                width: 25%;
                            }
                        }


                        h6 {
                            @media screen and (min-width:1900px) {
                                width: 25%;
                            }
                        }
                    }

                    &:nth-child(2) {
                        h6 {
                            width: 200px;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;

                            @include device-xll {
                                width: 100px;
                            }

                            @include device-xl {
                                width: 80px;
                            }

                            @include device-xlms {
                                width: 170px;
                            }
                        }

                        p {
                            width: 120px;

                            @media screen and (min-width:992px) {
                                width: 130px;
                            }

                            @media screen and (min-width:1300px) and (max-width:1500px) {
                                width: 130px;
                            }

                            @media screen and (min-width:1501px) {
                                width: 200px;
                            }

                            @media screen and (min-width:1200px) {
                                width: 100%;
                            }
                        }
                    }

                    .rap {
                        position: absolute;
                        top: 20px;
                        left: -6px;

                        @include device-md {
                            display: none;
                            top: 16px;
                        }

                        h6 {
                            color: $dark-black1;
                            margin: 0;
                            font-family: $semibold-font;
                            font-size: $font-14;
                            text-transform: capitalize;

                            @include device-md {
                                font-size: $font-12;
                            }
                        }

                        p {
                            font-family: $regular-font;
                            text-transform: capitalize;
                            font-size: $font-14;
                            margin: 0;

                            @include device-md {
                                font-size: $font-12;
                            }
                        }
                    }

                    .custom-width-percent {
                        border-bottom: 3px solid $blue-two;
                        top: 2px;
                        left: 10px;
                        position: absolute;
                        z-index: 1;

                        &.eightypercent {
                            width: 77%;
                        }

                        &.hundredpercent {
                            width: 98%;
                        }
                    }
                }

                &.custom-single-node {
                    li {
                        &:first-child {
                            width: 100%;

                            @include device-md {
                                width: 98%;
                            }

                            .rap {
                                text-align: left !important;

                                @media screen and (min-width: 992px) and (max-width: 1299px) {
                                    left: 0 !important;
                                    width: 200px;
                                }

                                h6 {
                                    @media screen and (min-width: 1100px) and (max-width: 1299px) {
                                        width: 55% !important;
                                    }
                                }
                            }

                        }

                        &:last-child {
                            .rap {
                                left: -230px;

                                @media screen and (min-width:992px) and (max-width:1500px) {
                                    left: -130px;
                                }

                                @media screen and (min-width:1600px) {
                                    left: -355px;
                                    width: 360px;
                                }

                                @media screen and (min-width:1800px) {
                                    left: -445px;
                                    width: 450px;
                                }

                                h6,
                                p {
                                    width: 240px;

                                    @media screen and (min-width:992px) and (max-width:1500px) {
                                        width: 130px;
                                    }

                                    @media screen and (min-width:1600px) {
                                        width: 100%;
                                    }
                                }

                                p {
                                    &.tooltip-custom {
                                        left: 185px;

                                        @media screen and (min-width:1600px) {
                                            left: 300px;
                                        }

                                        @media screen and (min-width:1800px) {
                                            left: 350px;
                                        }

                                        @media screen and (min-width:992px) and (max-width:1500px) {
                                            left: 80px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

        }

        .timeline li .rap p.tooltip-custom {
            font-family: $semibold-font;
            text-transform: capitalize;
            position: absolute;
            left: -30px;
            background: $white;
            font-size: $font-16;
            font-weight: 600;
            padding: 8px;
            border-radius: 5px;
            top: -2px;
            box-shadow: 0 0 6px 0 #0000001f;
            display: none;
            text-align: center;
            z-index: 10;
            text-overflow: inherit !important;
            white-space: inherit !important;
        }

        .timeline li:nth-child(2) .rap p.tooltip-custom {
            left: 45px;
            width: auto !important;
            white-space: nowrap !important;

            @media screen and (min-width:1200px) {
                left: 10%;
            }

            @media screen and (min-width:1400px) {
                left: 30%;
            }

            @media screen and (min-width:1800px) {
                left: 40%;
            }
        }

        .timeline li:first-child .rap p.tooltip-custom {
            width: auto !important;
            white-space: nowrap !important;
        }

        .timeline li:last-child .rap p.tooltip-custom {
            left: 45px;
            width: auto !important;
            white-space: nowrap !important;

            @media screen and (min-width:1200px) {
                left: 25%;
            }

            @media screen and (min-width:1400px) {
                left: 65%;
            }

            @media screen and (min-width:1800px) {
                left: 85%;
            }
        }

        .timeline li:hover .rap p.tooltip-custom {
            display: block;
        }
    }
}

.test-selection:checked+.selection-list {
    color: $text-color;
}

.close-button {
    cursor: pointer;
}

#globalsearch-result #faq .card .card-body .shipment-ref-no {
    display: none;
}

#globalsearch-result.contain-top.shipment-listall-wrap #faq .card .card-body .shipment-ref-no {
    top: 3px !important;
    display: block !important;
    left: 5px;
}

#globalsearch-result.contain-top.shipment-listall-wrap #faq .card .card-body #custom-sec-id .shipment-no+.shipment-ref-no {
    top: 4px !important;
}

#globalsearch-result.contain-top.shipment-listall-wrap #faq .card .card-body .status-contant img.view-eye {
    left: 6px;
}

.full-src {
    .blue-clr {
        color: $blue-two;
        font-size: $font-18;
        position: relative;
        top: 3px;
        left: 2px;
    }

    .red-clr {
        color: $red-color;
        font-size: $font-18;
        position: relative;
        top: 3px;
        left: 2px;
    }

    .orange-clr {
        color: $orange-color;
        font-size: $font-18;
        position: relative;
        top: 3px;
        left: 2px;
    }
}

#child .pga-setup .tableFixHead.pga-changes {
    max-height: 300px !important;
    height: auto !important;
}

app-booking-global-search {
    app-booking-list {
        .progress-icon-ctr {
            display: none !important;
        }
    }
}

app-customs-global-search {

    .bookings-total,
    .custom-mbl-number,
    .remove-global-search,
    .single-row .mobile-remove {
        display: none !important;
    }

    .booking-road-map.customs-road-map .road-maps .timeline {
        li {
            .rap {
                display: none;
            }

            &:first-child .rap,
            &:last-child .rap {
                display: inline-block;
            }

            &:first-child .rap h6 {
                width: 100% !important;
            }

            &:last-child .rap {
                left: -190px;

                h6 {
                    width: 200px;
                }

                p {
                    width: 200px;
                }
            }
        }
    }

    app-customs-list>div {
        padding: 0 !important;
    }

    app-customs-list {
        .progress-icon-ctr {
            display: none !important;
        }

        .custom-list-ctr .shiping-list-card .col-12.col-lg-3.col-xs-12.d-flex.align-items-start.center-progress.pr-0.booking-road-map.customs-road-map.medium-screen-ctrl {
            width: 52% !important;
            position: relative;
            right: 36px;

            @media screen and (min-width:1400px) {
                right: 30px;
            }

            @media screen and (min-width:1550px) {
                right: 20px;
            }
        }

        .custom-list-ctr .shiping-list-card .col-lg-3 {
            @media screen and (min-width:1550px) {
                width: 54% !important;
                right: 36px;
            }
        }

        .booking-road-map.customs-road-map .road-maps {
            right: -136px;

            @media screen and (max-width:2000px) {
                right: -120px;
            }

            @media screen and (max-width: 1650px) {
                right: -136px !important;
            }

            @media screen and (max-width: 1500px) {
                right: -100px !important;
            }
        }

        .custom-list-ctr .dashboard-state {
            left: 30px !important;

            @media screen and (min-width:2000px) {
                left: 54px !important;
            }

            @media screen and (max-width:1500px) {
                left: -10px !important;
            }
        }
    }

    .booking-road-map.customs-road-map .road-maps {
        right: -136px;
    }

    .custom-list-ctr .dashboard-state {
        left: 54px !important;

        @media screen and (max-width: 1650px) {
            left: 24px !important;
        }
    }

    .quick-search-wrap h3 {
        padding-top: 20px !important;
    }

    .quick-search-wrap span {
        vertical-align: middle;
    }
}

#dashboard-shipment .custom-list-ctr .shiping-list-card .col-lg-2 {
    width: 3.666667% !important;
}

#dashboardWatchlistWrapper {
    .road-maps {
        .timeline {
            @media screen and (max-width: 1600px) {
                width: 94%;
            }

            li {
                .rap {
                    display: none;
                }

                &:first-child,
                &:last-child {
                    .rap {
                        display: inline-block;
                    }
                }
            }
        }
    }

    .custom-list-ctr {
        .road-maps {
            .timeline {
                li {
                    &:after {
                        top: 2px !important;
                    }
                }
            }
        }
    }

    .btn-group {
        &.dropdown {
            button {
                &.custom-dropdown {
                    top: -8px;
                    left: 15px !important;
                }
            }
        }
    }

    .dashboard-state {
        .status-button {
            .booking-btn {
                @media screen and (min-width:1351px) and (max-width:1500px) {
                    position: relative;
                    left: -12px;
                }
            }
        }
    }

    .road-maps.move-set-right {
        right: 0;
    }

    #shipment-sec-id {
        .status-contant {
            .shipment-no {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                width: 85%;
            }
        }
    }

    .road-maps.move-set-right {
        right: 0;
    }

    #shipment-sec-id {
        .status-contant {
            .shipment-no {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                width: 85%;
            }
        }
    }

    .rap-sec {
        width: 81%;
    }

    app-shipment-list {
        .progress-icon-ctr {
            right: 14px;
        }

    }

    app-booking-list {
        .progress-icon-ctr {
            right: -20px;

            @media screen and (max-width: 1900px) {
                right: -8px;
            }

            .progress-icons {
                margin-right: 0;

                @media screen and (max-width: 1400px) {
                    margin-left: 2px;
                }
            }

        }
    }
}



ngb-datepicker-navigation-select .custom-select option {
    background: $white !important;
}

#customDocuments {
    &.overall-scroll {
        height: 75vh;
        overflow-y: scroll;
        margin-top: 30px !important;
    }
}

app-customs {
    .shipmet-title span img {
        right: -28px !important;

        @include device-xl {
            right: -4px !important;
        }
    }
}

#dashboardCustoms {
    @supports (-moz-appearance:button) and (contain:paint) {
        .custom-list-ctr {
            .shiping-list-card {
                .col-lg-2 {
                    @media screen and (min-width:992px) and (max-width: 1150px) {
                        width: 13.666667% !important;
                    }

                    @media screen and (min-width:1201px) {
                        width: 13.666667% !important;
                    }

                    @media screen and (min-width:1401px) {
                        width: 11.666667% !important;
                    }

                    @media screen and (min-width:1600px) {
                        width: 9.666667% !important;
                    }

                    @media screen and (min-width:1750px) {
                        width: 8.666667% !important;
                    }

                    @media screen and (min-width:1950px) {
                        width: 10.666667% !important;
                    }
                }
            }
        }
    }
}

app-customs {
    .shipmet-title span img {
        right: -28px !important;

        @include device-xl {
            right: -4px !important;
        }
    }
}

.customs-details-set .timeline li.eightypercent:last-child:after {
    display: block !important;
    position: absolute;
    top: -80px;
    z-index: 1;
    height: 100px;

    @media screen and (min-width: 1150px) and (max-width: 1350px) {
        top: -54px;
        height: 80px;
    }

}

.customs-details-set .timeline li.eightypercent:last-child .dynamic-progress {
    height: 80px;
    top: -80px;
    z-index: 2;

    @media screen and (min-width: 1150px) and (max-width: 1350px) {
        height: 55px;
        top: -54px;
    }
}

#myTabContent .tab-pane.show.active+.tab-pane.show.active .newuser-toast-bg.newuser-toast-bg1 {
    z-index: 1 !important;
}

#shipmentFull.shipmet-fullwidth #customDetail .shipment-detail .timeline li .rap {
    @media screen and (min-width:1150px) and (max-width:1450px) {
        width: 590px;

        @supports (-moz-appearance:button) and (contain:paint) {
            width: 500px !important;
        }
    }
}

#customDetail .shipment-detail .timeline li .rap p.address-sec {
    width: 94%;
}

#shipmentFull.shipmet-fullwidth .files-list {
    width: 65%;
    margin: 0 auto;
}

.image-container {
    .shipment-btn {
        &.red {
            background-color: $red-color !important;
        }

        &.orange {
            background-color: $orange-color !important;
        }
    }
}

#logs .tableFixHead thead tr.set-off th {
    padding-top: 10px !important;
    background: $white;
}

.shipmet-fullwidth {
    #customDetail {
        #home1 {
            @media screen and (min-width:1150px) and (max-width:1350px) {
                height: 48vh !important;
            }

            @media screen and (min-height: 550px) and (max-height: 600px) {
                height: 55vh !important;
            }

            @media screen and (min-height: 601px) and (max-height: 750px) {
                height: 60vh !important;
            }
        }

        .logs {
            height: 65vh;

            @supports (-moz-appearance:button) and (contain:paint) {
                height: 60vh;
            }

            &.add-details-custom {
                height: auto;
            }
        }
    }

    #customDocuments {
        height: 75vh;
        overflow-y: scroll;

        @supports (-moz-appearance:button) and (contain:paint) {
            height: 60vh;
        }

        @media screen and (max-height:750px) {
            height: 55vh;
        }

    }

    #customInvoice {
        @media screen and (max-height:750px) {
            height: 55vh !important;
        }

        @media screen and (max-height:650px) {
            height: 42vh !important;
        }

    }

    #customPga {
        @media screen and (max-height:750px) {
            height: 55vh !important;
        }
    }

    .detail-shipment .shipper-sec {
        @media screen and (min-width:1150px) {
            width: 650px !important;
        }
    }

    #logs1 {
        #accordion.logs {
            @media screen and (min-width:1150px) and (max-width:1350px) {
                height: 50vh !important;
            }

            @media screen and (min-width:1351px) and (max-width:1650px) {
                height: 65vh !important;
            }
        }
    }
}

.customs-details-set .timeline li.zeropercent:last-child:after {
    display: block !important;
    position: absolute;
    top: -81px;
    z-index: 1;
    height: 105px;

    @media screen and (min-width: 1150px) and (max-width: 1350px) {
        top: -82px;
        height: 105px;
    }
}

app-customs-details .shipmet-fullwidth #logs1 {
    height: 72vh;
    overflow-y: scroll;
    overflow-x: hidden;

    @media screen and (max-height: 750px) {
        height: 65vh !important;
    }

    @media screen and (max-height: 650px) {
        height: 40vh !important;
    }
}

#customDetail #home1 {
    @media screen and (min-width: 1401px) and (max-width: 1580px) {
        height: 75vh;
    }

    @media screen and (min-width: 992px) and (max-width: 1400px) {
        height: 75vh;
    }
}

#faq .card .card-body .shipment-customs-tab .view-shipment {
    width: auto !important;
}

#shipmentDetail #logs .table.log-table-border tbody tr td {
    word-break: break-word;
}

.fix-w {
    width: 150px;
}

.display-change-line {
    font-size: $font-16;
    letter-spacing: 0.32px;
    color: $dark-black1;
    font-family: $semibold-font;
}

.modal.right.data-field-modal-ctr .modal-dialog .pad-top {
    padding-top: 55px !important;
    padding-right: 45px;

    @media screen and (min-width:1351px)and (max-width:1650px) {
        padding-top: 55px !important;
    }
}

.modal.right.data-field-modal-ctr .modal-dialog .close {
    top: 18px;

    @media screen and (min-width:1351px)and (max-width:1650px) {
        top: 16px;
    }
}

app-workflow-filter [type=checkbox]:checked+label:after,
app-datafield-filters [type=checkbox]:checked+label:after {
    top: 8.5px;
    left: 5px;
    height: 7px;
    width: 7px;
    background-size: 7px 7px;

    @media screen and (max-width:1600px) {
        left: 4.5px;
    }
}

.disabled-div {
    .ng-input {
        cursor: not-allowed !important;

        input {
            cursor: not-allowed !important;
        }

    }
}

.right-nav .status-update .dropdown {

    &.show button:hover {
        border: 2px solid $blue-one !important;
    }

    button:hover {
        border: 2px solid $blue-one !important;
    }
}

app-customs-list {
    .add-user-popup {
        height: 330px !important;
    }
}

app-action-detail-tab {
    .accordion-button {
        text-transform: inherit !important;
    }
}

.data-field-modal-ctr {
    z-index: 999 !important;
}

.datafield-toastmsg {
    .newuser-toast-bg {
        top: -15px !important;
    }

    .contain-bg {
        padding: 6px 0px 6px 20px !important;
    }

    .close-button {
        position: relative;
        top: -2px !important;
        left: 5px !important;
        margin-left: 0 !important;
    }
}

.link-to-shipment {
    color: $text-color;
    text-decoration: underline;
    cursor: pointer;
}

.toasterMsg {

    .booking-global-search,
    .customs-global-search {

        #shipment-sec-id.infiniteScroll.booking-list-ctr,
        #custom-sec-id.infiniteScroll.custom-list-ctr {
            height: auto !important;
            padding-bottom: 0 !important;
        }
    }

    .modal.right.data-field-modal-ctr {
        app-workflow-email-configuration {
            .modal-dialog.update-slider {
                top: 0 !important;
            }
        }
    }

    .modal.right.data-field-modal-ctr .modal-dialog {
        top: 120px !important;

        &.update-slider {
            top: 80px !important;
        }

        &.modal-dialog-centered.modal-lg {
            top: 0 !important;
        }
    }

    .data-field-footer {
        bottom: 115px !important;
    }

    .modal.right.data-field-modal-ctr .modal-content {
        height: 78% !important;

        @media screen and (min-width:1150px)and (max-width:1350px) {
            height: 64% !important;
        }

        @media screen and (min-width:1351px)and (max-width:1650px) {
            height: 72% !important;
        }
    }

    .modal.right.data-field-modal-ctr #previewModule {
        top: -100px !important;

        .modal-content {
            height: auto !important;
        }
    }

    #shipment-sec-id.infiniteScroll.shipment-list-ctr,
    #shipment-sec-id.infiniteScroll.booking-list-ctr,
    #custom-sec-id.infiniteScroll.custom-list-ctr {
        @media screen and (max-width: 1800px) {
            height: calc(86vh - 160px) !important;

            @supports (-moz-appearance:button) and (contain:paint) {
                height: calc(76vh - 160px) !important;
            }
        }

        @media screen and (min-width: 1920px) {
            height: calc(91vh - 160px) !important;

            @supports (-moz-appearance:button) and (contain:paint) {
                height: calc(81vh - 160px) !important;
            }
        }

        @media screen and (min-width: 1150px) and (max-width: 1450px) {
            height: 65vh !important;

            @supports (-moz-appearance:button) and (contain:paint) {
                height: 50vh !important;
            }
        }
    }

    .update-status {
        .tableFixHead {
            &.change-h {
                &.action-list {
                    height: 77vh !important;

                    @media screen and (min-width: 1150px) and (max-width:1350px) {
                        height: 58vh !important;
                    }

                    @media screen and (min-width: 1351px) and (max-width:1650px) {
                        height: 66vh !important;
                    }

                    @media screen and (max-width:991px) {
                        height: 65vh !important;
                    }
                }
            }
        }
    }
}

app-action-field-detail-tab {
    .newuser-toast-bg {
        width: 600px !important;
        margin: auto;
    }
}


.add-user-popups {
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
        font-family: $webbold-font;
        padding: 0 6px;
    }

    &.notification-one {
        height: 72px;
        margin: 0 0 30px;
    }
}

.datafield-create-rapper {
    .datafield-header {
        #myTab {
            .Workflow-header {
                display: none;
            }
        }
    }
}

.table-sortable>thead>tr>th.set-twof.remove-hand,
.table thead th.remove-hand {
    cursor: default;
}


#shipment-sec-id,
#custom-sec-id {
    &.infiniteScroll {
        @media screen and (min-width:1150px) and (max-width:1450px) {
            height: 75vh !important;

            @supports (-moz-appearance:button) and (contain:paint) {
                height: 58vh !important;
            }
        }

    }
}

#globalSearch {

    #shipment-sec-id,
    #custom-sec-id {
        &.infiniteScroll {
            @media screen and (min-width:1150px) and (max-width:1450px) {
                height: auto !important;
            }

        }
    }
}

.data-field-modal-ctr {
    .nav-tabs {
        padding-bottom: 1px;
    }
}

app-datafield-filters,
app-workflow-filter {
    #wrap-type .dropdown-menu {
        transform: initial !important;
        top: 27px !important;
    }

    .filter-selct-btn:focus,
    .filter-selct-btn:hover {
        color: $text-color;
        font-weight: 600;
        border-color: $blue-one;
    }
}

#globalSearch {
    .shiping-list-card .progress-bar-section .timeline {
        right: 6px;
    }

    .shipment-btn {
        position: relative;
        left: 10px;
    }

    .booking-road-map.customs-road-map .road-maps .timeline {
        left: 28px;
    }

    #shipment-sec-id,
    #custom-sec-id {
        &.infiniteScroll {
            @media screen and (min-width:1150px) and (max-width:1450px) {
                height: auto !important;
            }

        }
    }
}

app-datafield-add-condition {
    .custom-style .autocomplete-container .input-container input {
        height: 50px !important;
    }

    .autocomplete-container .suggestions-container ul {
        max-height: 102px;

        li a {
            padding: 5px 15px;
        }
    }

    .autocomplete-container .not-found {
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
    }
}

.data-field-modal-ctr {
    .ng-dropdown-panel .scroll-host {
        max-height: 160px;

        @media screen and (max-width:1600px) {
            max-height: 160px;
        }
    }
}

#dropdownUser1 {
    #profileSec {
        cursor: pointer;
    }
}

.data-field-form-ctr-modal {
    app-default-display-value-tool {
        mention-list.null {
            top: 130px !important;
            left: 14px !important;
            right: 0 !important;
        }

        .scrollable-menu {
            padding: 0 !important;
            border: none !important;
            box-shadow: 1px 3px 10px 0 rgb(0 0 0 / 14%);
            max-height: 160px !important;
            width: 64.5% !important;

            @media screen and (min-width: 1150px) and (max-width:1350px) {
                max-height: 130px !important;
                width: 62.5% !important;
            }

            @media screen and (min-width: 1921px) {
                width: 65.5% !important;
            }
        }

        .mention-active>a {
            font-size: $font-16;
            color: $dark-black1 !important;
            background-color: transparent !important;
        }
    }

    .condition-rap {
        app-default-display-value-tool {
            mention-list.null {
                left: 3px !important;
            }

            .scrollable-menu {
                width: 47.5% !important;

                @media screen and (min-width: 1921px) {
                    width: 48.5% !important;
                }
            }
        }
    }

    .is-invalid {
        color: $org-red;
        margin-top: 3px;
    }
}

.ng-autocomplete.custom-style.is-invalid-border .autocomplete-container .input-container input {
    border: 1px solid red !important;
}

#accountAutoComplete {
    border: none !important;
}

.update-status {
    .tableFixHead {
        @media screen and (min-width:1900px) {
            height: 73vh !important;
            padding-bottom: 20px;

            @supports (-moz-appearance:button) and (contain:paint) {
                height: 62vh !important;
            }
        }

        &.change-h {
            @media screen and (min-width:1900px) {
                height: 81vh !important;

                @supports (-moz-appearance:button) and (contain:paint) {
                    height: 68vh !important;
                }
            }

            @media screen and (min-width:2200px) {
                height: 84vh !important;
            }

            @media screen and (min-width:2300px) {
                height: 88vh !important;
            }
        }
    }

    &.new-change-padding {
        @media screen and (min-width:1900px) {
            height: 76vh !important;
            padding-bottom: 0;

            @supports (-moz-appearance:button) and (contain:paint) {
                height: 66vh !important;
            }
        }
    }
}

.quick-search-wrap.new-design-set {
    display: none;
}

.workflow-form-ctr {

    ng-select.add-user-popups .ng-select-container {
        max-height: 105px;
    }
}

.workflow-condition-ctr {

    ng-select.add-user-popups {
        margin: 0;

        &.ng-select-disabled:hover {
            border: 2px solid #d7e4ea !important;
        }
    }
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value:empty {
    display: none;
}

.workflow-toast {
    .newuser-toast-bg {
        top: 30px !important;
    }
}

.workflow-condition-ctr {
    .ng-select .ng-select-container .ng-value-container {
        display: initial;
        flex: inherit;
    }

    ng-select.add-user-popups .ng-select-container.ng-has-value .ng-value {
        height: auto;
        min-height: inherit;
        padding: 3px 15px;
        white-space: initial;

        /*.ng-value-label {
            width: 87%;
            display: inline-block;
            padding: 2px 0;
        }

        .ng-value-icon {
            top: 5px
        }*/
    }

    ng-select.add-user-popups.specific-fix .ng-select-container.ng-has-value .ng-value {
        +.ng-input {
            display: none;
        }
    }

    .ng-select.ng-select-single.ng-select-filtered .ng-select-container .ng-value-container .ng-value {
        visibility: visible;
    }

    .ng-select .ng-select-container .ng-value-container .ng-input>input {
        width: auto;
    }

    .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value .ng-value-icon {
        display: block;
        position: relative;
        top: 2px;
    }

    .ng-select-container .ng-value-container .ng-placeholder {
        position: absolute;
    }

    ng-select.add-user-popups {
        padding: 9px 10px;
        min-height: 50px;
    }

    ng-select.add-user-popups .ng-select-container.ng-has-value .ng-value {
        margin-bottom: 0;
        padding: 5px 12px;
        font-size: $font-14;
    }
}

.workflow_email-template-ctr {
    .workflow-condition-ctr .ng-select .ng-select-container .ng-value-container .ng-input>input {
        width: 100%;
        height: 22px;
    }

    ng-select.add-user-popups .ng-select-container.ng-has-value .ng-value {
        margin-bottom: 5px !important;
        height: auto !important;

    }

    ng-select.add-user-popups .ng-select-container.ng-has-value .ng-value-icon.left {
        top: 2px;
    }

    ng-select.add-user-popups {
        .ng-spinner-loader {
            position: absolute;
            right: 0;
            top: 5px;
        }
    }


    .ngb-tp {
        padding-left: 15px;

        .ngb-tp-meridian {
            button {
                border: 1px solid $blue-three !important;
                background-color: $blue-three !important;
                color: $white;
                border-radius: 50px;

                &:hover {
                    color: $white !important;
                    box-shadow: none !important;
                    background: #D7E4EA !important;
                    border: 1px solid #D7E4EA !important;
                }

                &:focus {
                    box-shadow: none !important;
                    border: 1px solid $blue-three !important;
                }
            }
        }

        .btn-link {
            color: #ced4da !important;

            &:hover,
            &:focus {
                box-shadow: none !important;
                color: $dark-black1 !important;
            }
        }
    }
}

.workflow-form-ctr {
    .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
        white-space: normal;
        height: auto;
        display: inline-block;
    }
}

.data-field-form-ctr-modal {
    .ngb-dp-arrow {
        .ngb-dp-arrow-btn {
            left: 9.5%;
        }

        &.right {
            .ngb-dp-arrow-btn {
                left: inherit;
                right: 10%;
            }
        }
    }
}

.data-field-form-ctr {
    .dispaly-con-ctr {
        .loader {
            top: 45px;
        }
    }
}

.remove-dropdown-wf-condition {
    .ng-dropdown-panel.ng-select-bottom {
        display: none;
    }
}

.ng-select.specific-fix .ng-spinner-loader {
    position: absolute;
    right: 0;
    top: 5px;
}

.mb-5.pb-3.dfd-bottom-ctrl {
    margin-bottom: 20px !important;
    padding-bottom: 0 !important;
}

.data-field-form-ctr.date-range-control-dfd {
    margin-bottom: 4rem;
}

.attention-alert .dropdown .dropdown-menu {
    z-index: 1;
}

.dfm-add-case {
    .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value .ng-value-icon {
        display: block;
        position: relative;
        top: 4px;
        cursor: pointer;
    }

    .ng-select.add-user-popups.specific-fix .ng-select-container.ng-has-value .ng-value+.ng-input {
        display: none;
    }

    &.remove-dropdown-wf-condition {
        .ng-dropdown-panel.ng-select-bottom {
            display: none;
        }
    }
}

.remove-dropdown-wf-condition .ng-dropdown-panel.ng-select-bottom {
    display: none;
}

.dfm-add-case {
    .ng-select-container .ng-value-container .ng-placeholder {
        position: absolute;
    }

    ng-select.add-user-popups ng-dropdown-panel {
        top: 26px;
    }

    ng-select.add-user-popups {
        margin-bottom: 0;
    }
}

#inside-accordtion {
    .ngb-tp-meridian {
        .btn-outline-primary {
            outline: 0;
            background: $blue-three !important;
            color: $white !important;

            &:hover {
                background: #D7E4EA !important;
                border: 1px solid #D7E4EA !important;
                color: $white !important;
                outline: 0;
            }

            &:focus {
                box-shadow: none;
                border: 1px solid $blue-three !important;
            }
        }
    }

    .accordion-item {
        border: none !important;
    }
}

.btn-preference-group {
    .btn-group {
        position: relative;
        display: inline-flex;
        vertical-align: middle;
        width: 96.5%;
    }

    .custom-menu {
        width: 100%;
        background-color: $white;
        color: $dark-black1;
        font-weight: 600;
        overflow: hidden;
        text-align: left;
        border: 2px solid #d7e4ea;
        position: relative;
        left: 0px;
        height: 50px;
        border-radius: 5px;

        &:before {
            content: "";
            position: absolute;
            top: 50%;
            width: 0.5rem;
            height: 0.1rem;
            transition: all 0.1s;
            border: none;
        }
    }

    .dropdown-menu {
        position: absolute;
        z-index: 1000;
        display: none;
        min-width: 10rem;
        padding: 0;
        margin: 0;
        font-size: $font-1rem;
        color: $dark-black2;
        text-align: left;
        list-style: none;
        background-color: $white;
        background-clip: padding-box;
        border: 1px solid #00000026;
        max-height: 200px;
        overflow-y: scroll;
        right: 0;
        border-radius: 0 0 0.25rem 0.25rem;
    }

    .field-module {
        .dropdown-menu {
            width: 99%;
            left: 2px !important;
        }
    }

    .custom-drop-menu {
        min-width: 100%;
        border: none;
        width: 100%;
        margin: 0 !important;
        padding: 0;
    }

    .dropdown-item {
        display: block;
        width: 100%;
        padding: 8px 30px 8px 15px;
        clear: both;
        font-weight: 400;
        color: $dark-black2;
        text-align: inherit;
        text-decoration: none;
        white-space: nowrap;
        background-color: initial;
        border: 0;

        &:hover {
            background: #EAF3F5;
        }

        input[type=checkbox] {
            position: absolute;
            left: 0;
            opacity: .01;
        }

        label {
            position: relative;
            font-size: $font-16;
            line-height: 1;
            cursor: pointer;
            display: inline-block;
        }
    }

    .custom-arow:before {
        content: "";
        position: absolute;
        top: 50%;
        width: 0.5rem;
        height: 0.1rem;
        transition: all .1s;
        border: none;
    }

    .dropdown .dropdown-toggle.custom-arow:after {
        content: "";
        border: none;
        background-image: url(/../../assets/img/df-dropdown.svg);
        background-position: 50%;
        display: inline-block;
        width: 30px;
        height: 18px;
        background-repeat: no-repeat;
        position: absolute;
        right: 0;
        top: 15px;
        background-color: $white;
        transform: rotate(0deg);
    }

    .dropdown-menu.show {
        width: 100%;
        box-shadow: none;
        padding: 0;
        top: 48px !important;
        left: 0 !important;
        transform: none !important;
        border: 1px solid $blue-three;
        border-top: 0;
    }

    .btn-secondary.dropdown-toggle {
        background-color: $white !important;
        color: $dark-black1 !important;
    }

    .show>.btn-secondary.dropdown-toggle {
        background-color: $white !important;
        color: $dark-black1 !important;
    }

    .rnw-ctr-dob {
        padding-left: 20px;
    }

    .rnw-ctr-dob-text {
        margin-left: 5px;
        margin-top: 15px;
        margin-bottom: 10px !important;
    }

    .rnw-ctr-dob-inner-row {
        >div {
            padding: 2px !important;
        }

        .close-icon-np {
            position: relative;
            top: 10px;
            left: -5px;
            cursor: pointer;
        }
    }
}

.action-field-mgmnt-ctr {
    ng-select.add-user-popups {
        margin: 0;
        padding: 12px 10px;
        height: auto !important;
    }
}

.app-action-field-details {
    ng-select.add-user-popups .ng-select-container.ng-has-value .ng-value-icon.left {
        font-size: 24px;
        color: $text-color;
    }
}


.actionfield-toastmsg .newuser-toast-bg {
    top: 20px !important;
}

.action-field-off-screen-ctr {
    .action-field-mgmnt-ctr {
        width: 100% !important;
        height: 88vh !important;

        @media screen and (min-width: 1150px) and (max-width: 1350px) {
            height: 76vh !important;
        }

        @media screen and (min-width: 1351px) and (max-width: 1600px) {
            height: 82vh !important;
        }
    }

    .action-field-footer {
        left: 0 !important;
        bottom: 0 !important;
    }

    .is-invalid {
        color: $org-red;
        margin-top: 3px;
    }

    #actionFieldAccount {
        width: 100% !important;
    }

    .action-account-field {
        #accountName {
            width: 35% !important;
        }

        #last-th {
            width: 20% !important;
        }
    }
}

#actionFieldAccount {
    .drag-add-btn-ctr {
        margin-top: 20px;
        font-size: $font-12;
        font-weight: 600;
        text-align: right;
        height: 24px;
    }
}

.action-field-off-screen-ctr .data-field-modal-ctr .modal-dialog .pad-top {
    padding-right: 25px !important;
}

.update-status .ng-star-inserted .tableFixHead.data-workflow-list-ctrl {
    @media screen and (min-width:1150px) and (max-width: 1350px) {
        height: 65vh !important;
    }

    @media screen and (min-width:1351px) and (max-width: 1699px) {
        height: 70vh !important;
    }

    @media screen and (min-width:1700px) {
        height: 76vh !important;
    }
}

#codes .tableFixHead tr:last-child .dropdown .dropdown-menu {
    top: -35px !important;
}

#codes .tableFixHead tr:first-child .dropdown .dropdown-menu {
    top: 0px !important;
}

.update-status #actionFieldAccount .tableFixHead {
    height: 77vh !important;
    padding-bottom: 20px;

    @media screen and (min-width:1150px) and (max-width: 1350px) {
        height: 55vh !important;
    }

    @media screen and (min-width:1351px) and (max-width: 1699px) {
        height: 65vh !important;
    }
}

.update-status .action-field-off-screen-ctr #actionFieldAccount .tableFixHead {
    height: 86vh !important;
    padding-bottom: 20px;

    @media screen and (min-width:1150px) and (max-width: 1350px) {
        height: 66vh !important;
    }

    @media screen and (min-width:1351px) and (max-width: 1699px) {
        height: 76vh !important;
    }
}

.oia-container {
    &.nav-toggle {
        .filter-nav-inner-fullview {
            .shipmet-title {
                display: none;
            }
        }

        .report-container {
            @media screen and (min-width:1150px) and (max-width: 1350px) {
                height: 90vh !important;
            }

            @media screen and (min-width:1351px) and (max-width: 1650px) {
                height: 92vh !important;
            }
        }
    }
}

app-action-detail-tab {
    .dropdown-menu.show {
        width: 100%;
        padding: 0;
        border-right: 2px solid $blue-three !important;
        border-left: 2px solid $blue-three !important;
        border-bottom: 2px solid $blue-three !important;
        top: -3px !important;
        border-radius: 0 0 5px 5px;
        border-top: none !important;
        transform: translate3d(0px, 48px, 0px) !important;
    }

    .dropdown-menu {
        width: 100%;
        padding: 0;
        z-index: 999;

        &.toggle-show {
            border: 2px solid $blue-one;
            border-top: none;
            top: 45px;
            border-radius: 0 0 0.25rem 0.25rem;

            .dropdown-item {
                &:hover {
                    background-color: $white !important;
                }
            }
        }

        .dropdown-item {
            &:hover {
                background-color: #eaf3f5 !important;
            }
        }
    }

    ngb-datepicker-navigation-select>.custom-select:focus {
        left: 0;
        box-shadow: none;
    }

    button.btn.btn-link.ngb-dp-arrow-btn:focus {
        box-shadow: none;
    }

    .btn-light:not(:disabled):not(.disabled).active,
    .btn-light:not(:disabled):not(.disabled):active,
    .show>.btn-light.dropdown-toggle,
    .btn-light.bg-primary.text-white {
        color: $dark-black1 !important;
        background-color: #EAF3F5 !important;
        border-color: #EAF3F5 !important;
        border-radius: 50px;
    }

    .btn-check:focus+.btn,
    .btn:focus,
    .accordion-button:focus {
        box-shadow: none;
    }

    .action-detail-field {
        .loader-center {
            top: 60%;
            left: 78%;
        }
    }

    #action {
        height: 75vh;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .shipmet-fullwidth {
        #action {
            height: 60vh;
            overflow-y: scroll;
            overflow-x: hidden;
            margin-top: 370px;
            padding-bottom: 100px;

            .no-data {
                top: 300px;
            }
        }
    }

    .custom-drop-menu li:hover {
        background: initial;
    }

    .set-one,
    .set-two {
        .custom-drop-menu li:hover {
            background-color: initial;
        }
    }
}

.shimpent-removeicon {
    #action {
        .over-all-rap {
            height: 62vh;
            margin-top: 350px;
        }
    }

    #action-tab-bookings .over-all-rap,
    #action-tab-cutoms .over-all-rap {
        height: 62vh;
        padding-bottom: 150px;
        margin-top: 60x;
    }
}

.oia-container {
    .oia-content-wrap {
        width: 100%;

        &.adjest-top-set {
            margin-top: 0;
        }
    }
}

.report-td-ctr {
    .ng-autocomplete.custom-style .autocomplete-container .input-container input {
        position: relative;
        top: 1px;
        border-radius: .25rem;

        &:hover {
            border: 2px solid $blue-three;
        }
    }
}

.report-container {
    height: 100vh;
    margin: 8px auto;
    width: 100%;

    .report-container-scroll {
        height: 98vh;
        overflow-y: scroll;
    }
}

#overlay {
    @include device-sm {
        width: 100%;
        right: inherit;
    }
}

.table-action-field {
    &.acnt-mngmt-tab {
        .ng-autocomplete.custom-style .autocomplete-container .input-container input {
            width: 100% !important;
        }

        .ng-autocomplete.custom-style.is-invalid-border .autocomplete-container .input-container input {
            width: 100%;
        }

        .autocomplete-container .not-found {
            position: absolute;
            left: 0;
            right: 0;
            z-index: 10;
        }

        td {
            padding: 15px 0;
        }
    }
}

.custom-drop-menu {
    .dropdown-item {
        cursor: pointer;

        &:hover {
            background-color: #eaf3f5;
        }
    }
}

#actionfield {
    .table-action-field {
        .autocomplete-container .suggestions-container {
            top: 38px;
            box-shadow: none;

            ul {
                border: 2px solid $blue-one;
                border-top: 0;
                border-radius: 0 0 5px 5px;

                &:empty {
                    border: none;
                }
            }
        }
    }
}

#dashboardShipmentWrapper .progress-bar-section .timeline li:last-child .rap p,
#dashboardWatchlistWrapper .progress-bar-section .timeline li:last-child .rap p {
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;

    @media screen and (min-width: 1150px) and (max-width: 1350px) {
        width: 80px !important;
    }
}

#dashboardShipmentWrapper {
    .rap-sec {
        width: 81%;
    }

    .std-cntr.two-icon-cntr {
        .doc-first-row.doc-std {
            right: 48px !important;
        }
    }
}

#action,
#action-tab-bookings,
#action-tab-cutoms {
    .over-all-rap {
        height: 75vh;
        padding-bottom: 80px;

        @media screen and (min-width: 1150px) and (max-width: 1350px) {
            height: 62vh;
        }

        @media screen and (min-width: 1351px) and (max-width: 1600px) {
            height: 71vh;
            padding-bottom: 80px;
        }

        @include device-sm {
            height: 70vh;
            padding-bottom: 0;
        }
    }
}

.container-color-bg .container-color-bg-ctrl h5 {
    color: $dark-black1;
    font-size: $font-16;
    letter-spacing: 0.32px;
    font-family: $light-font;
}

#bookingDetailTab {
    h5.displayset.d-block {
        color: $dark-black1;
        font-size: $font-16;
        letter-spacing: 0.32px;
        font-family: $light-font;

        @include device-md {
            font-size: $font-15;
            margin: 0 !important;
            padding-bottom: 4px;
        }
    }
}

.cdk-drop-list {
    .table-action-field {
        td {
            padding: 15px 0;
        }
    }
}

.new-pipe-design {
    h4 {
        color: $dark-black1;
        font-size: $font-18;
        font-family: $webbold-font;
        text-transform: uppercase;
        padding: 16px 0 10px;
    }

    h5 {
        color: $dark-black1;
        font-size: $font-16;
        letter-spacing: .32px;
        font-style: "titlliumWeb-light, Sans-serif" !important;
    }

    p {
        font-size: $font-16;
        letter-spacing: .32px;
        color: $dark-black1;
        font-family: $semibold-font !important;
        font-weight: 600;
    }

    .new-design {
        .rap1 {
            width: 33.33%;
            display: inline-block;
        }
    }
}


.action-field-mgmnt-ctr {
    .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
        display: initial;
        flex: inherit;

        .ng-value {
            display: inline-block;
        }
    }
}

.add-user-popup.new-filter {
    .autocomplete-container .suggestions-container.is-visible {
        top: 33px;
        box-shadow: none;

        ul {
            border: 2px solid $blue-one;
            border-top: none;
            border-radius: 0 0 5px 5px;

            &:empty {
                visibility: hidden;
                height: 0;
                display: none;
            }
        }
    }
}

.status-contant {
    b {
        background: #b8eff9 !important;
        padding: 0;
        text-transform: initial;
    }

    .shipment-pickup-txt,
    .shipment-ref-no {
        b {
            font-weight: normal;
        }
    }
}

#dashboardWatchlistWrapper .shiping-list-card .dropdown-section {
    right: 4px !important;
}

app-report-filter [type=checkbox]:checked+label:after {
    left: 5px;
}

.oia-container {
    &.nav-toggle {
        .global-left-menu-ctr {
            .accordion-item {
                .accordion-body {
                    max-height: 265px;
                    overflow-y: scroll;
                }
            }
        }
    }
}

app-template-filters {
    .admin-filter-contr {

        button#locationFilter-account:hover,
        button#button-global:hover,
        button#button-module:hover {
            border: 2px solid $blue-one;
        }
    }
}

.slick-list {
    .ck-content .table .ck-table-resized {
        table-layout: initial !important;
    }
}

.new-data-field-form {
    img {
        cursor: pointer;
    }
}

.table-action-field.table-view-admin {
    .field-txt-td {
        .ng-autocomplete.custom-style .autocomplete-container .input-container input {
            font-size: $font-12;
        }
    }

    .ng-autocomplete.custom-style .autocomplete-container .input-container input {
        position: relative;
        top: -5px;
    }
}


.field-txt-td {

    .drap-info-msg {
        position: absolute;
        height: fit-content !important;
        padding: 10px;
        background-color: $white;
        border-radius: 8px;
        display: none;
        font-size: $font-12;
        z-index: 1;
        width: 222px !important;
        max-height: 105px !important;
        top: 62px !important;
        left: -15px;
        box-shadow: -3px 3px 10px 2px #00000010;
        overflow: initial !important;

        @media screen and (min-width: 1650px) {
            left: 5px;
        }

        .close {
            width: 20px;
            position: absolute;
            right: 0;
            z-index: 10;

            img {
                cursor: pointer;
            }
        }

        &:after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            margin-left: -0.5em;
            box-sizing: border-box;
            border-color: $black $black $white $white;
            border-style: solid;
            border-width: 0.5em;
            transform-origin: 0 0;
            top: 0%;
            left: 162px;
            transform: rotate(-225deg);
            box-shadow: -3px 3px 10px 2px #00000010;

            @media screen and (min-width: 1650px) {
                left: 200px;
            }
        }

        .drap-info-msg-inner {
            max-height: 85px;
            overflow-y: scroll;
            text-align: left;
            padding: 10px 20px 10px 10px !important;
        }
    }
}

.field-limit-text {
    color: blue !important;
    cursor: pointer;
}

.more-btn-set {
    border: none;
    background: none;
    color: $text-color;
    text-decoration: underline;
    text-transform: capitalize;
    font-weight: 500;
}

.new-more-btn.modal {
    position: absolute;
    z-index: 99999999;
    top: -100px;
    height: auto;
    width: 300px;

    .modal-dialog {
        margin: 0;
    }

    .modal-body {
        padding: 0.4rem;
    }

    .modal-content {
        border: none;

        .close {
            text-align: right;

            img {
                width: 16px;
                margin: 4px;
            }
        }
    }
}

.template-mgmt {
    .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
        white-space: normal !important;
        height: auto !important;
    }

    ng-select.add-user-popups .ng-select-container.ng-has-value .ng-value {
        border-radius: 20px;
        padding: 5px 10px;
    }
}

.table-view-admin-ctr {
    .table-view-admin {
        .autocomplete-container .suggestions-container {
            max-height: 130px;
            overflow-y: hidden;

            ul {
                max-height: 90px;
            }
        }

        tr:last-child {
            .autocomplete-container .suggestions-container {
                max-height: 85px;
            }
        }
    }
}

.new-ship-custom-filters {
    margin-top: -45px;

    .shipment-flex-wrap {
        .btn-group:first-child {
            height: 32px;
            padding: 4px 8px 4px 28px;
            min-width: 128px;
            font-family: $semibold-font;
            font-size: $font-16;
            border-radius: 5px;
            margin-left: 10px !important;

            &.filterDropdown {
                height: auto;
                position: relative;
                left: -15px;
            }
        }
    }
}

.table-view-ctr {
    .table-view-ctr-cntrl {

        td {
            vertical-align: middle;
        }

        .shiping-list-card {

            // .progress-bar-section .timeline li:nth-child(2) .rap {
            //     visibility: hidden;
            // }

            .progress-bar-section .timeline li:nth-last-child(2) .rap {
                visibility: hidden;
            }
        }
    }
}

app-advanced-filter-tab {
    .table-map-w.input-set-control {
        .col-md-12 {
            padding: 0 !important;
        }

        .field-reference {
            input {
                width: 100%;
                border: 1px solid #D7E4EA;
                border-left: 1px solid #D7E4EA !important;
                top: 0;
                font-size: $font-12;
                height: 27.6px;
                padding: 4px;
                position: relative;

                &.is-invalid-border {
                    border: 1px solid red !important;
                    height: 27.6px;
                    border-radius: 0;
                    border-right: 3px solid red !important;
                }
            }
        }
    }


    .workflow-cond1-last {
        .btn:focus {
            box-shadow: none;
        }

        #dropdownMenuButton:hover {
            border: 1px solid $blue-three;
        }

        .is-invalid-border {
            border: 1px solid red !important;
            height: 26.6px;
            border-radius: 0;
            border-right: 3px solid red !important;
        }

        .dropdown-menu {
            width: 100%;
            border: 1px solid $blue-three;
            top: -2px !important;
            border-top: none;
        }

        .btn-group.dropdown {
            width: 100%
        }

        button {
            background: $white;
            border: 1px solid #D7E4EA;
            color: #404040;
            font-size: $font-12;
            border-radius: 0;
            height: 27.6px;

            &.custom-menu {
                padding: 4px 12px 0;
                text-align: left;
            }


            &.btn.btn-link {
                border: none;
            }
        }

        .dropdown-toggle::after {
            display: none;
        }

        .dropdown-toggle::before {
            content: "";
            border: none;
            background-image: url(/../../assets/img/df-dropdown.svg);
            background-position: 50%;
            display: inline-block;
            width: 10px;
            height: 16px;
            background-repeat: no-repeat;
            position: absolute;
            right: 6px;
            top: 6px;
            background-color: $white;
            transform: rotate(0deg);
            background-size: 14px 4px;
        }
    }

}

//background-color-progress-bar
.shiping-list-card .progress-bar-section {
    .timeline {
        .progtrckr-todo {
            &.red~.progtrckr-todo:after {
                background: #ffdede;
            }

            &.orange~.progtrckr-todo:after {
                background: #fcead9;
            }
        }
    }
}

#shipment-sec-id {
    .shiping-list-card {
        .progress-bar-section {
            .timeline {
                &.pb-active {
                    .progtrckr-todo {
                        .dynamic-progress.progtrckr-todo {
                            left: -1px;
                        }
                    }

                    // .progtrckr-done~.progtrckr-todo:after {
                    //     left: -3px;
                    // }
                }

                .progtrckr-todo {
                    &.red~.progtrckr-todo:after {
                        background: #ffdede;
                    }

                    &.orange~.progtrckr-todo:after {
                        background: #fcead9;
                    }
                }

                .progtrckr-done {
                    &.red~.progtrckr-todo:after {
                        background: #ffdede;
                    }

                    &.orange~.progtrckr-todo:after {
                        background: #fcead9;
                    }
                }
            }
        }
    }
}

.table-map-w {
    .field-reference input {
        width: 100%;
        border: 1px solid #D7E4EA;
        border-left: 1px solid #D7E4EA !important;
        top: 0;
        font-size: $font-12;
        height: 28px;
        padding: 4px;
        position: relative;
    }
}


.left-set {
    width: 60px;
    display: inline-block;
}

.right-set {
    background: #F5F5F5;
    border: 1px solid #D7E4EA;
    border-radius: 5px;
    width: calc(100% - 60px);
    padding: 8px;
    display: inline-block;

    .first-set {
        text-align: right;
    }
}

#dashboardWatchlistWrapper .custom-list-ctr {
    .dropdown button img {
        top: 24px !important;
    }

    .progress-icon-ctr .progress-icons img {
        @media screen and (min-width:1150px) and (max-width:1650px) {
            position: relative;
            left: -7px;
        }
    }

    .booking-btn {
        width: 115px;

        @media screen and (min-width:1150px) and (max-width:1650px) {
            width: 100px;
            margin-top: 10px;
            position: relative;
            left: 5px;
        }
    }
}

#dashboardWatchlistWrapper app-booking-list .booking-list-ctr {
    .progress-icon-ctr .progress-icons {
        margin-left: 14px;
        position: static;

        @media screen and (min-width:1150px) and (max-width:1350px) {
            margin-left: 0;
        }

        @media screen and (min-width:1351px) and (max-width:1650px) {
            margin-left: 7px;
        }

        @media screen and (max-width: 1900px) {
            margin-left: 8px;
        }
    }

    .dropdown button img {
        top: 0 !important;
    }
}

#dashboardWatchlistWrapper .custom-list-ctr {
    .shiping-list-card.first .dropdown-menu.show {
        top: 15px !important;
    }
}

.table-view-overall-ctr {
    position: relative;
}

.right-set-search {
    position: absolute;
    top: 5px;
    right: 24px;
    background: $white;
    z-index: 99;
    cursor: pointer;
}

app-usermanagment-filter {
    .right-set-search {
        right: 10px;
    }
}

app-common-shipper-type-filter,
app-all-location-filter {
    .right-set-search {
        top: 2px;
        right: 8px;
    }
}

#searchArea-account {
    .right-set-search {
        top: 2px;
        right: 8px;
    }
}

.table-action-field {
    .ng-autocomplete.custom-style .autocomplete-container .input-container .x i {
        display: none;
    }
}

#globalsearch-result #search-result-bookings {
    .view-shipment {
        left: 10px;
    }

    .progress-icon-ctr {
        right: 40px;

        .progress-icons {
            @media screen and (min-width:1700px) {
                margin-right: 0;
            }
        }
    }
}

@media screen and (min-width: 1450px) {
    .update-status .tableFixHead.workflow-tableFixHead {
        height: 70vh;
    }

    app-workflow-header+.tab-content #logs {
        height: 85vh;
    }
}

.shipment-customs-tab {
    margin-bottom: 200px;
}

.account-fullwidth {
    .pad-top {
        padding-top: 6% !important;
    }
}

.hide-icon .first-icon {
    display: none;
}

.grecaptcha-badge {
    visibility: hidden;
}


.flter-dateRange {

    .ngb-dp-header {
        border: none !important;

        .ngb-dp-arrow {
            position: inherit !important;
        }
    }

    ngb-datepicker .btn {
        width: auto;
    }

    .ngb-dp-arrow .ngb-dp-navigation-chevron {
        background: none !important;
        border-style: solid !important;
        border-width: 0.2em 0.2em 0 0 !important;
        width: 0.75em !important;
        height: 0.75em !important;
        margin-left: 0.25em !important;
        margin-right: 0.15em !important;
        transform: rotate(-135deg) !important;

    }

    .ngb-dp-arrow.right .ngb-dp-navigation-chevron {
        transform: rotate(45deg) !important;
        margin-right: -0.5rem !important;
    }

    .ngb-dp-month {
        width: auto;
    }
}

#invoice-table {
    thead {
        border: none !important;
    }
}

#dashboardShipmentWrapper,
#dashboardWatchlistWrapper {
    .ship-list-icns .doc-common-hover.shre-ctrl {
        left: inherit !important;
        right: -8px;
    }
}

#dashboardShipmentWrapper {
    .shr-first-row.shr-std {
        top: -6px !important;
        right: 35px !important;
    }
}

#dashboardWatchlistWrapper {
    .shr-first-row.shr-std {
        top: -6px !important;
        right: 20px !important;
    }

    .act-first-row.act-std {
        top: -6px !important;
        right: 35px !important;
    }

    .doc-first-row.doc-std {
        top: -6px !important;
        right: 70px !important;
    }

    .std-cntr {
        &.two-icon-cntr {
            .doc-first-row.doc-std {
                right: 33px !important;
            }
        }
    }

    #custom-sec-id {
        .customs-list-icns .doc-common-hover.shre-ctrl {
            top: -30px;
            left: inherit;
            right: -10px;

            @media screen and (min-width: 1350px) and (max-width: 1650px) {
                right: 0px;
            }

        }

        .action-field-count {
            @media screen and (min-width: 1350px) and (max-width: 1650px) {
                position: relative;
                left: -8px;
            }
        }

        .customs-list-icns .doc-common-hover.action-fld-ctrl {
            @media screen and (min-width: 1350px) and (max-width: 1650px) {
                left: -24px;
                top: -55px;
            }
        }
    }

    .booking-list-icns .doc-common-hover.action-fld-ctrl {
        left: inherit;
        right: 15px;

        @media screen and (min-width: 1350px) and (max-width: 1650px) {
            right: 5px;
        }
    }
}

#dashboard-shipment {
    .booking-list-icns .doc-common-hover.shre-ctrl {
        top: -35px;
        left: inherit;
        right: -10px;
    }
}

#dashboardWatchlistWrapper .custom-list-ctr .progress-icon-ctr .progress-icons img {
    top: 7px;
}

#custom-sec-id {
    .customs-list-icns .doc-common-hover.shre-ctrl {
        top: -23px;
        left: -10px;
    }
}


.progress-bar-table-view {
    .shiping-list-card {
        .progress-bar-section .timeline li {
            &:hover .rap p.update-origin {
                display: none !important;
            }

            &:hover .rap {
                visibility: visible !important;
                background: #ffffff;
                padding: 6px 8px;
                border-radius: 5px;
                box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);
            }

            &:hover:nth-last-child(2) .rap {
                background: #fff !important;
                padding: 6px 8px !important;
                border-radius: 5px !important;
                box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12) !important;
                left: -105px;
            }

            &:nth-child(2):hover .rap {
                visibility: visible !important;
                background: #fff !important;
                padding: 6px 8px !important;
                border-radius: 5px !important;
                box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12) !important;

                .update-origin {
                    display: none;
                }
            }

            &:nth-child(2):last-child:hover .rap {
                .update-origin {
                    display: none;
                }
            }
        }
    }
}

#customFIlter+#shipmentListSelector {
    .shr-first-row {
        right: 18px !important;
    }

    .doc-first-row.doc-std {
        right: 90px !important;
    }

    .ship-list-icns .doc-common-hover.shre-ctrl {
        right: -10px;
        left: inherit !important;
    }


    .std-cntr {
        .shr-first-row {
            right: 36px !important;
        }
    }
}


app-customs-custom-filters+app-customs-list #custom-sec-id {
    .customs-list-icns .doc-common-hover.shre-ctrl {
        top: -25px;
        left: -7px;
    }

    .progress-icons {
        position: relative;
    }

    .progress-icon-ctr {
        top: 10px;
    }

    .doc-common-hover.action-fld-ctrl {
        left: -25px;
    }
}

app-booking-custom-filters+app-booking-list {
    .progress-icon-ctr {
        top: 10px !important;
    }
}

app-customs-custom-filters,
app-booking-custom-filters {
    .custom-day.range {
        border-radius: 0 !important;
    }

    ngb-datepicker ngb-datepicker-month .ngb-dp-weekday {
        text-indent: 8px;
    }
}

.shiping-list-card .progress-bar-section .timeline.pb-active.leg-1 li:last-child:before {
    right: 0;
}

#globalsearch-result {
    #search-result-customs .booking-road-map.customs-road-map .road-maps {
        li {
            &:first-child .rap h6 {
                text-overflow: ellipsis;
            }

            &:last-child .rap {
                text-overflow: ellipsis;
                width: 165px;
                left: -155px;
            }
        }
    }
}

.table-view-ctr-cntrl {
    #shipment-table-view-sec .status-img .blue .empty-set {
        width: 24px !important;
        height: 24px !important;
        position: relative;
        top: 4px;
    }
}

app-booking-custom-filters,
app-shipment-custom-filters,
app-customs-custom-filters {

    ngb-datepicker .ngb-dp-arrow button.btn.btn-link.ngb-dp-arrow-btn {
        right: 210px !important;
    }

    ngb-datepicker .ngb-dp-arrow.right button.btn.btn-link.ngb-dp-arrow-btn {
        right: 50px !important;
    }

    ngb-datepicker-navigation-select .custom-select {
        text-align: center;

        &:first-child {
            margin-left: -12px !important;
        }

        &:focus {
            left: 0 !important;
        }

        +.custom-select {
            margin-left: 10px;
        }
    }
}

app-shipment-custom-filters {
    #advancedfilter {
        ngb-datepicker .ngb-dp-arrow button.btn.btn-link.ngb-dp-arrow-btn {
            left: 15px;
            right: inherit;
        }

        ngb-datepicker .ngb-dp-arrow.right button.btn.btn-link.ngb-dp-arrow-btn {
            right: 15px !important;
            left: inherit !important;
        }
    }
}

.module-rap {
    ngb-datepicker ngb-datepicker-month .ngb-dp-weekday:first-child {
        margin-left: 10px;
    }

    ngb-datepicker ngb-datepicker-month .ngb-dp-weekdays {
        border-top: solid 1px #d7e4ea;
    }

    ngb-datepicker ngb-datepicker-month .ngb-dp-weekday {
        border-top: none !important;
    }

    ngb-datepicker-navigation-select>.custom-select {
        &:focus {
            left: 0 !important;
        }

        &:first-child {
            margin-left: -15px !important;
        }
    }
}

#actionField-scroll-div {
    .btn-group.dropdown {
        .close-button {
            right: 30px;
            z-index: 99;
        }
    }

    .close-button {
        position: absolute;
        right: 10px;
        top: 10px;
        background: #ffffff;

        img {
            width: 28px;
            height: 28px;
        }
    }
}

#dashboardWatchlistWrapper #custom-sec-id .action-field-count {
    @media screen and (min-width: 1300px) and (max-width: 1500px) {
        top: 6px;
    }
}

.toasterMsg {
    .global-left-menu-ctr {
        #accordionPanelsStayOpenExample {
            height: 76vh !important;

            @media screen and (min-width: 1351px) and (max-width: 1600px) {
                height: 65vh !important;
            }

            @media screen and (min-width: 1151px) and (max-width: 1350px) {
                height: 55vh !important;
            }

            @media screen and (max-width: 1150px) and (min-height: 700px) {
                height: 65vh !important;
            }

            @media screen and (max-width: 1350px) and (min-height: 650px) and (max-height: 699px) {
                height: 60vh !important;
            }
        }
    }
}


.dashboard-rap {
    .std-cntr {
        overflow-y: scroll;
        height: 508px;
    }

    .dashboard-bookings .booking-btn {
        width: 110px !important;
    }

    #dashboard-bookings .dashboard-state {
        width: 88px !important;
        margin-left: 10px;

        @media screen and (min-width:1800px) {
            width: 110px !important;
        }
    }

    #dashboardCustoms .shiping-list-card .dropdown-section {
        @media screen and (min-width:1651px) {
            right: -10px !important;
        }

        @media screen and (min-width:1280px) and (max-width:1650px) {
            right: 0 !important;
        }
    }

    #dashboardWatchlistWrapper .shiping-list-card .dropdown-section {
        @media screen and (min-width:768px) and (max-width:1199px) {
            right: -10px !important;
        }
    }

    #shipment-sec-id .shiping-list-card .dropdown-section .dropdown button {
        @media screen and (max-width:1199px) {
            left: 5px;
        }
    }

    .dashboard-bookings {
        .sortby-wrap .child {
            right: inherit !important;
        }

        .btn-group.dropdown {
            width: auto;
        }
    }

    @media screen and (min-width:992px) and (max-width:1200px) {
        .dashboard-bookings {
            width: 50% !important;
        }

        .btn-group.dropdown button {
            right: 60px !important;
        }

        #dashboardCustoms .btn-group.dropdown button {
            left: -50px !important;
        }

        .dashboard-bookings .shiping-list-card .dropdown-section {
            right: 30px !important;
        }

        .dashboard-bookings .status-button {
            position: relative;
            left: -50px !important;
        }
    }

    @media screen and (max-width:991px) {
        #dashboard-bookings .status-button {
            position: relative;
            right: 50px;
        }

        .dashboard-bookings #shipment-sec-id .shiping-list-card .dropdown-section .dropdown button {
            left: -15px;
            top: 0;
        }

        .dashboard-bookings .btn-group.dropdown {
            width: 50%;
            left: 10px !important;
        }

        #dashboardCustoms .btn-group.dropdown button {
            top: -20px !important;
        }
    }

    @media screen and (max-width:767px) {
        #dashboardBooking .shiping-list-card {
            padding: 15px 0 25px;
        }

        .add-user-popup .discription {
            background: #fff;
            padding-bottom: 20px;
            border-radius: 0 0 5px 5px;
        }

        #split-shipment .shiping-list-card {
            padding: 15px 10px 25px 10px;
        }

        #dashboardCustoms .btn-group.dropdown button {
            width: 120px;
            left: -40px;
        }

        #dashboard-bookings .status-button {
            right: 15px;
        }

        #custom-sec-id {
            #dashboard-bookings .status-button {
                right: 25px;
            }
        }

        #shipment-sec-id .shiping-list-card .dropdown-section .dropdown button {
            left: 15px;
            top: -5px;
        }

        #dashboard-bookings #shipment-sec-id .shiping-list-card .dropdown-section .dropdown button {
            top: 25px;
        }

        #custom-sec-id .shiping-list-card .dropdown-section .dropdown button {
            top: 25px !important;
        }

        .dashboard-bookings .booking-btn {
            position: relative;
            left: 15px;
            top: -10px;
        }

        #custom-sec-id .dashboard-bookings .booking-btn {
            left: 40px;
        }

        #custom-sec-id .status-button {
            top: 10px !important;
            right: 25px;
        }

    }



    @media screen and (max-width:450px) {

        #dashboardShipmentWrapper .progress-bar-section .timeline li:first-child .rap p,
        #dashboardWatchlistWrapper .progress-bar-section .timeline li:first-child .rap p {
            width: 70px !important;
        }
    }

    .shiping-list-card .dropdown-section .dropdown button {
        @media screen and (min-width: 1200px) and (max-width: 1279px) {
            left: 20px;
        }
    }
}

.dashboard-rap .std-cntr {
    overflow-y: scroll;
    height: 508px;

    &.scroll-empty {
        overflow-y: hidden;
    }
}

.my-profile-toast.delete-filter {
    .newuser-toast-bg {
        top: 70px;
    }
}

#customFIlter+#shipmentListSelector {
    .table-cntr {
        &.two-icon-cntr {
            .shr-first-row {
                right: 45px !important;
            }
        }
    }

    .std-cntr {
        &.two-icon-cntr {
            .doc-first-row.doc-std {
                right: 52px !important;
            }
        }
    }
}

@-moz-document url-prefix() {
    .contain-top {
        &.dashboard-rap {
            height: 92vh !important;
        }
    }
}

app-user-management {
    .tableFixHead .dropdown .dropdown-menu {
        @include device-sm {
            z-index: 1;
        }
    }
}


#editUser.modal {
    @include device-sm {
        margin-top: 55px;
    }
}

app-shipment-list {
    .infiniteScroll {
        &.shipment-list-ctr {
            @-moz-document url-prefix() {
                padding-bottom: 40px;
            }

            @media screen and (max-width:1800px) {
                @-moz-document url-prefix() {
                    height: calc(95vh - 160px) !important;
                }
            }
        }
    }
}

#customFilter+app-booking-list {
    .booking-list-ctr {
        padding-top: 15px;
    }
}