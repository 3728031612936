//Default fonts
$font-1rem: 1rem;
$font-1125rem: 1.125rem;
$font-10: 10px;
$font-11: 11px;
$font-12: 12px;
$font-13: 13px;
$font-14: 14px;
$font-15: 15px;
$font-16: 16px;
$font-18: 18px;
$font-20: 20px;
$font-22: 22px;
$font-24: 24px;
$font-26: 26px;

//Default fonts

$regular-font: "titilliumweb-regular, Sans-serif";
$light-font: "titilliumWeb-light, Sans-serif";
$semibold-font: "titilliumWeb-semibold, Sans-serif";
$webbold-font: "titilliumWeb-bold, Sans-serif";

// Default colors

$white: #ffffff;
$black: #000000;

$text-color: #01457c;
$bgcolor: #eaf3f5;
$bgcolor-second: #eaf3f540;
$bgcolor-third: #eaf3f563;
$border-color: #d7e4ea;
$border-color1: #7d898f;
$text-color-one: #5f5f5f;
$ass-color-new: #b7b7b7;

$org-red: red;
$red-color: #ff4040;
$new-red-color: #F05E5E;
$orange-color: #f8a34e;
$text-purp: #cccccc;
$dark-black: #434343;
$dark-black1: #3e3e3e;
$dark-black2: #212529;
$bg-color: #fafafa;

$yellow: #ffc000;
$yellow-one: #ffe46f;
$yellow-two: #4389a9;
$blue-one: #14cbeb;
$blue-two: #5cc7e7;
$blue-three: #14caeb;
$blue-four: #17caeb;


$background_set: #f5f9fa;
$header_text_color: #FDFDFD;
$fade_out: rgba(0, 0, 0, 0.267);